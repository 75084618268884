import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Field, ErrorMessage } from 'formik';
import ReactTooltip from 'react-tooltip'

const InputFormikField = ({className = '', errors, touched, values, setFieldError,  name, placeholder = '', label = '', withLabel = true, withErrorMessage = true, withErrorTooltip = false, type = 'text', tooltipPlacement= "top"}) => {

    let tooltipRef = useRef();
    let previousValue = useRef('');
    const [tooltipIsOn, setTooltipIsOn] = useState(false);

    let inputError = errors[name];
    let inputValue = values[name];
    let inputTouched = touched[name];

    const resetErrorsForField = useCallback(() => {
                setFieldError(name, undefined);
        }, [setFieldError, name]);

    useEffect(() => {

        if(withErrorTooltip === true) {

            if (previousValue.current !== inputValue) {
                if (tooltipIsOn) {
                    //console.log('value changed while tooltip on');
                    resetErrorsForField(name);
                    ReactTooltip.hide(tooltipRef);
                }
            } else if (!tooltipIsOn && inputTouched && inputError) {
                //console.log('showTooltip' +name);
                ReactTooltip.show(tooltipRef);
            } else if (!tooltipIsOn && !inputError) {
                //console.log('hideTooltip' + name);
                ReactTooltip.hide(tooltipRef);
            }
        }
        previousValue.current = inputValue;

    }, [name, inputError, inputValue, inputTouched, withErrorTooltip, tooltipIsOn, resetErrorsForField]);

    return (
        <div className={"form-group form-label-group " + className}>
            {withLabel ? <label htmlFor={name}>{label}</label> : null}
            <Field name={name} type={type}
                   className={'form-control ' + (errors[name] && touched[name] ? ' is-invalid' : '')}
                   placeholder={placeholder}/>
            {withErrorMessage ? <ErrorMessage name={name} component="div" className="invalid-feedback"/> : null}
            {withErrorTooltip ? (
                <React.Fragment>
                    <p ref={ref => tooltipRef = ref} data-tip={errors[name]} className={''} data-for={name} />
                    <ReactTooltip
                        afterShow={() => {setTooltipIsOn(true)}}
                        afterHide={() => {setTooltipIsOn(false)}}
                        place={tooltipPlacement} effect="float" id={name} type='error'
                    />
                </React.Fragment>
            ) : null}

        </div>
)
};

export default InputFormikField;