import React from 'react';
import Modal from 'react-bootstrap4-modal';
import CloseModalIcon from "./CloseModalIcon";
import copyMessages from '../../../lang/it/messages';
import styled from "styled-components";

interface IModalThanksgivingProps {
    className? : string;
    isOpen: boolean;
    dismissModal: VoidFunction;
}

const ModalThanksgiving: React.FC<IModalThanksgivingProps> = ({className = '', isOpen, dismissModal, children}) => {

    return (
        <Modal visible={isOpen} className={className + ' modal-thanksgiving-form'}
               dialogClassName={'modal-xl modal-dialog modal-dialog-top p-4'} onClickBackdrop={dismissModal}>
            <div className="modal-body text-center p-5 position-relative">
                <CloseModalIcon onClicked={dismissModal} className={'position-absolute top-right'}/>
                <div className={'text-center'}>
                    <h2 className={'how-to-receive text-standard-violet'}>{copyMessages.form.thanksgiving.modal.howToReceive}</h2>
                    <p className={'choose-mode'}>{copyMessages.form.thanksgiving.modal.chooseMode}</p>
                    <p className={'fill-a-field'}>{copyMessages.form.thanksgiving.modal.fillAtLeastAField}</p>
                </div>
                {children}
            </div>
        </Modal>
    )

};

const StyledModalThanksgiving = styled(ModalThanksgiving)`
    .how-to-receive {
      font-size: 40px;
    }
    .choose-mode {
        font-size: 25px;
    }
    
    .fill-a-field {
       font-size: 25px;
    }
`;

StyledModalThanksgiving.displayName = 'StyledModalThanksgiving';

export default StyledModalThanksgiving;