import React from 'react';
import {IDetailsRaw, INormalizedDetailName} from "../../../typization/commonTypesInterfaces";
import CardNecrologioHomeDetail from "../Cards/CardNecrologioHome/CardNecrologioHomeDetail";
import {useSelector} from "react-redux";
import {
    selectedDeceasedAssociationSelector,
    selectedDeceasedDetailsSelector, selectedDeceasedManifestSelector,
} from "../../../redux/selectors/selectedDeceasedSelector";
import CardNecrologioDetail from "../Cards/Base/CardNecrologioDetail";
import AssociationIcon from "../Images/Icons/AssociationIcon";

export interface IHomeDetailModalContentProps {
    className?: string;
    contentName: '' | keyof IDetailsRaw;
}

export const HomeDetailModalContent: React.FC<IHomeDetailModalContentProps> = ({className = '', contentName}) => {

    const selectedDeceasedDetails = useSelector(selectedDeceasedDetailsSelector);
    const selectedDeceasedAssociation = useSelector(selectedDeceasedAssociationSelector);
    const selectedDeceasedManifest = useSelector(selectedDeceasedManifestSelector);

    if(contentName == '') return null;

    if(contentName == 'association') {
        return (
            <CardNecrologioDetail>
                <h3 className={'text-uppercase text-standard-violet mb-4'}>DONAZIONE</h3>
                <AssociationIcon  isActive={true} />
                <h4 className={'d-block my-3'}>{selectedDeceasedAssociation.text}</h4>
                <div className={`row ${selectedDeceasedAssociation.id != '' ? '' : 'd-none'}`}>
                    <div className="offset-3 col-2">
                        <img className={"img-fluid"} src={selectedDeceasedAssociation.logoSrc} alt={"association-logo"}/>
                    </div>
                    <div className={"col-5 d-flex align-items-center"}>
                        <h4>{selectedDeceasedAssociation.name}</h4>
                    </div>
                </div>
            </CardNecrologioDetail>
            )
    }

    if(contentName == 'manifest') {
        return (
            <CardNecrologioDetail>
                {/*<h3 className={'text-uppercase text-standard-violet mb-3'}>MANIFESTO</h3> */}
                <img className={selectedDeceasedManifest.orig == '' ? 'd-none' : 'img-fluid'} src={selectedDeceasedManifest.orig} alt={'manifest-orig'}/>
            </CardNecrologioDetail>
        )
    }

    const foundDetails = selectedDeceasedDetails.filter(detail => detail.name ==  contentName );

    const detail = foundDetails[0];

    const modalTitleByTypology = ((typology:  INormalizedDetailName) : string =>{

        const titles = {
            'church': 'Destinazione salma',
            'family': 'Messaggio da parte della Famiglia',
            'funeral': 'Cerimonia Funebre',
            'announcement': 'Ne danno il triste annuncio',
        };

        return titles[typology];
    })(detail.name);

    return (
        <CardNecrologioHomeDetail className={className} detail={detail} iconActive={true}>
            {(Icon, _, fullDetailText) => (
                <React.Fragment>
                    <Icon/>
                    <div className={'content-detail mt-30'}>
                        <h3 className={'text-uppercase text-standard-violet'}>{modalTitleByTypology}</h3>
                        <p className="detail-text">{fullDetailText}</p>
                    </div>
                </React.Fragment>
            )}
        </CardNecrologioHomeDetail>
    )

};

export default HomeDetailModalContent;