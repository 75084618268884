import React from 'react';

class CountDown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeLeft: this.props.secondsToEnd || 30,
        };

        this.initCountdown = this.initCountdown.bind(this);
        this.oneSecondWithCallbackOnEnd = this.oneSecondWithCallbackOnEnd.bind(this);
        this.clearCountdown = this.clearCountdown.bind(this);
        this.resetTimeLeft = this.resetTimeLeft.bind(this);
    }

    componentDidMount() {
        if (this.props.isCounting) {
            this.initCountdown()
        }
    }

    componentDidUpdate(prevProps){

        if(prevProps.secondsToEnd !== this.props.secondsToEnd){
            this.clearCountdown();
            this.resetTimeLeft();
            this.initCountdown();
        } else if(prevProps.isCounting === false &&  this.props.isCounting === true) {
            this.initCountdown()
        } else if(prevProps.isCounting === true &&  this.props.isCounting === false) {
            this.clearCountdown();
            this.resetTimeLeft();
        }
    }

    componentWillUnmount() {
        this.clearCountdown();
    }

    initCountdown() {
        this.countdown = setInterval(this.oneSecondWithCallbackOnEnd, 1000);
    }

    oneSecondWithCallbackOnEnd() {
        //console.log('ticking');
        this.setState(({timeLeft}) => ({timeLeft: timeLeft - 1}),
        () => {

            if (this.state.timeLeft === 0) {
                clearInterval(this.countdown);
                this.props.onTimeOver();
            }
        })
    }

    clearCountdown() {
        if(this.countdown) {
            clearInterval(this.countdown);
        }
    }

    resetTimeLeft(){
        this.setState({timeLeft: this.props.secondsToEnd || 30})
    }

    render() {
        if(!this.props.children) return null;
        return this.props.children(this.state.timeLeft);
    }


};

export default CountDown;