import {combineReducers} from "redux";
import {userSlice} from "./slices/userSlice";
import {customsSlice} from "./slices/customsSlice";
import {pingApiSlice} from "./slices/pingApiSlice";
import {selectedDeceasedSlice} from "./slices/selectedDeceasedSlice";
import {deviceStatusSlice} from "./slices/deviceStatusSlice";
import {availableDeceasedSlice} from "./slices/availableDeceasedSlice";
import {initApiSlice} from "./slices/initApiSlice";

const rootReducer = combineReducers({
    user : userSlice.reducer,
    availableDeceased: availableDeceasedSlice.reducer,
    selectedDeceased: selectedDeceasedSlice.reducer,
    customs: customsSlice.reducer,
    apis: combineReducers({
        ping: pingApiSlice.reducer,
        init: initApiSlice.reducer
    }),
    deviceStatus: deviceStatusSlice.reducer,
});
export default rootReducer;