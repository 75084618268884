import * as React from 'react';
import Alert from "../Base/Alert";

const ErrorsAlert : React.FC<{className?: string;errors: string[]}> = ({errors, className = ''}) => (
    <Alert typology={`danger ${className}`}>
        <ul className="list-unstyled">
            {errors.map((err, index) => (<li className={'font-weight-bold'} key={index}>{err}</li>))}
        </ul>
    </Alert>
);

export default ErrorsAlert;
