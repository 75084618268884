import {createSlice} from "@reduxjs/toolkit";
import {fetchPingApi} from "../actions/pingApiActions";
import {
    IApiAssociation, IAssociation,
    IDeceased,
    IDetailsRaw,
    IHome, IManifest, INormalizedDetail,
    INormBaseInfos,
    IScreensaverApiDeceased,
    ISign
} from "../../typization/commonTypesInterfaces";

// **** SLICE STATE TYPE ******//

export type selectedDeceasedSliceState = IDeceased & { orariSalma: string};

const getStoredDeceased = () : IHome => {

    //new redux
    let storedSelectedDeceasedData : string | null = localStorage.getItem('selected_deceased');
    if (storedSelectedDeceasedData && storedSelectedDeceasedData !== "undefined"   ){
        let parsedStoredSelectedDeceasedData : IHome = JSON.parse(storedSelectedDeceasedData);
        if ('srcFotoDefunto' in parsedStoredSelectedDeceasedData && 'signs' in parsedStoredSelectedDeceasedData){
            return parsedStoredSelectedDeceasedData;
        }
    }

    //retrocompatibility
    let storedHomeData : string | null = localStorage.getItem('home');
    if (storedHomeData && storedHomeData !== "undefined"   ){
        let parsedHomeData : IHome = JSON.parse(storedHomeData);
        if ('srcFotoDefunto' in parsedHomeData && 'signs' in parsedHomeData){
            return parsedHomeData;
        }
    }
    return {
        id_defunto: 0,
        srcFotoDefunto: '',
        displayName: '',
        nascita: '',
        decesso: '',
        coniugato: '',
        note: '',
        txtFunerale: '',
        txtTrattamento: '',
        txtAnnunciano: '',
        signs: [],
        associazione : [],
        manifesto: {
            thumb: '',
            orig: ''
        }
    };
};

export const getDeceasedFromApiData = (personData : IHome | IScreensaverApiDeceased) : IDeceased => {
    console.log('id defunto', personData.id_defunto)
    let baseInfos : INormBaseInfos = {
        imgSrc: personData.srcFotoDefunto,
        name: personData.displayName,
        birth: personData.nascita,
        death: personData.decesso,
        married: personData.coniugato,
    };

    let detailsRaw : IDetailsRaw  =  {
        family: personData.note,
        announcement: personData.txtAnnunciano,
        funeral: personData.txtFunerale,
        church:  personData.txtTrattamento,
        manifest : personData.manifesto,
        association: Array.isArray(personData.associazione) ? {} as IApiAssociation : personData.associazione
    };

    let normalizedDetails :  INormalizedDetail[] = [
        { name: 'family', text: detailsRaw.family} as INormalizedDetail,
        { name: 'announcement', text: detailsRaw.announcement}  as INormalizedDetail,
        { name: 'funeral', text: detailsRaw.church}  as INormalizedDetail,
        { name: 'church', text: detailsRaw.church}  as INormalizedDetail,
    ].filter((detail) => detail.text != '');

    const association : IAssociation = (() : IAssociation => {
        if(detailsRaw.association.id_associazione === undefined ){
            return { } as IAssociation;
        }
        return {
            name: detailsRaw.association.associazione,
            id: detailsRaw.association.id_associazione,
            link: detailsRaw.association.linkAssociazione,
            logoSrc: detailsRaw.association.logoAssociazione,
            text: detailsRaw.association.txtDonazione,
        }
    })();

    //@ts-ignore
    let messages : ISign[] = personData.signs ?  [...personData.signs] : [];

    const formattedDeceased  = {
        id: +personData.id_defunto,
        baseInfos : baseInfos,
        details : normalizedDetails,
        messages : messages,
        association: association,
        manifest : detailsRaw.manifest
    };

    console.log('formattedDeceased', formattedDeceased)

    return formattedDeceased
};

const getInitialState = () => {
    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
       return {...window.initialCypressState.selectedDeceased}
    }

    return getDeceasedFromApiData(getStoredDeceased());
};

// **** SLICE ******//

export const selectedDeceasedSlice = createSlice({
    name: 'selectedDeceased',
    initialState: getInitialState() as selectedDeceasedSliceState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPingApi.fulfilled, (state, action) => {
                if(action.payload?.home){

                    const currentDeceased = getDeceasedFromApiData(action.payload.home);

                    state.id = currentDeceased.id;
                    state.baseInfos = currentDeceased.baseInfos;
                    state.details = currentDeceased.details;
                    state.manifest = currentDeceased.manifest;
                    state.association = currentDeceased.association;
                    state.messages = [...currentDeceased.messages];

                }
            })
            .addDefaultCase((state, action) => {

            })
    }
});
