import {createSlice} from "@reduxjs/toolkit";
import {authenticateUserByCredentials} from "../actions/userActions";

// **** SLICE STATE TYPE ******//

export type initApiSliceState = {
    isLoading: boolean;
    error: string;
}

const getInitialState = (): initApiSliceState => {
    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
        return {...window.initialCypressState.apis.init}
    }

    return {
        isLoading: false,
        error: ''
    }
};

// **** SLICE ******//

export const initApiSlice = createSlice({
    name: 'init',
    initialState: getInitialState() as initApiSliceState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder

        .addCase(authenticateUserByCredentials.pending, (state, action) => {
            state.isLoading = true;
            state.error = '';
        })
        .addCase(authenticateUserByCredentials.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = '';
        })
        .addCase(authenticateUserByCredentials.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload || action.error.message;
        })
        .addDefaultCase((state, action) => {
        })
    }
});
