import {createSlice} from "@reduxjs/toolkit";
import {fetchPingApi} from "../actions/pingApiActions";
import {getDeceasedFromApiData} from "./selectedDeceasedSlice";
import {IDeceased} from "../../typization/commonTypesInterfaces";

// **** SLICE STATE TYPE ******//

export type AvailableDeceasedSliceState = IDeceased[];

const getInitialState = (): AvailableDeceasedSliceState => {

    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
        return [...window.initialCypressState.availableDeceased]
    }

    const baseInitialState = [] as AvailableDeceasedSliceState;

    try {
        let storedAvailableDeceasedData : string | null = localStorage.getItem('available_deceased');
        if(storedAvailableDeceasedData && storedAvailableDeceasedData !== 'undefined'){
            let parsedStoredAvailableDeceasedData = JSON.parse(storedAvailableDeceasedData);
            if(parsedStoredAvailableDeceasedData.isArray) {
                return parsedStoredAvailableDeceasedData;
            }
        }

        //retrocompatibility
        let storedScreensaver : string | null = localStorage.getItem('screenSaver');
        if (storedScreensaver && storedScreensaver !== "undefined" ){
            let parsedScreensaver = JSON.parse(storedScreensaver);
            if (parsedScreensaver.isArray ){
                return parsedScreensaver;
            }

        }

        return baseInitialState;

    } catch(e){

        return baseInitialState;

    }
};

// **** SLICE ******//

export const availableDeceasedSlice = createSlice({
    name: 'availableDeceased',
    initialState: getInitialState() as AvailableDeceasedSliceState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPingApi.fulfilled, (state, action) => {
                let nextDeceased = [] as AvailableDeceasedSliceState;
                if('screenSaver' in action.payload){
                    nextDeceased = action.payload?.screenSaver?.map(getDeceasedFromApiData) as AvailableDeceasedSliceState;
                    while(state.length > 0){
                        state.pop();
                    }
                    nextDeceased.forEach((deceased) => {
                        state.push(deceased);
                    })
                }
            })
            .addDefaultCase((state, action) => {
            })
    }
});
