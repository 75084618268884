import React from 'react';

export interface IChurchIconProps {
    className? : string;
    isActive?: boolean;
}


const ChurchIcon : React.FC<IChurchIconProps> = ({className = '', isActive = false}) => (
    <img className={'icon icon-curch ' + className} src={`/images/icons/church${isActive ? '-active' : ''}.png`} alt={'church'}/>
);

export default ChurchIcon;