import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import NIPinger from "../../apis/NIPinger";
import {RootState} from "../store";
import {setUserIsNotEnabled} from "./userActions";
import commonCopy from '../../lang/it/common';
import {IPingHocSubmit, IPingRequest} from "../../typization/commonTypesInterfaces";

export const pingApiSetShouldPing = createAction<boolean>('PING_API_SET_SHOULD_PING');

export const setPingApiTiming = createAction<number>('SET_PING_API_TIMING');

export const fetchPingApi = createAsyncThunk('FETCH_PING_API',
    async (request: IPingHocSubmit, {getState, dispatch, rejectWithValue}) => {

        //@ts-ignore
        const {deviceId} = getState().user;
        //@ts-ignore
        const {firstCall} = getState().apis.ping;
        //@ts-ignore
        const deviceStatus = getState().deviceStatus;

        let fullRequest : IPingRequest = {
            deviceId: deviceId,
            firstCall: firstCall,
            status: deviceStatus,
            ignoreCache: 1,
            ...request //submitSign if available
        };
        try {
            const apiPingInstance = new NIPinger();

            const response = await apiPingInstance.handle(fullRequest);

            return response;

        } catch (error){

            let message = commonCopy.apiErrors.networkError;

            if((error.code && error.code == 403 )|| (error.status && error.status == 403)){
                dispatch(setUserIsNotEnabled());
                return rejectWithValue(commonCopy.apiErrors.userNotEnabled);
            }

            if (error.response) {
                message = error.response.data.msg;

            } else {
                message = error.message;
            }

            return rejectWithValue(message);
        }

    },
{
    condition: (request, { getState, extra }) => {

        console.log('request', request)
        const state = getState() as RootState;

        if(state.apis.ping.isLoading){
            return false;
        }

        if('submitSign' in request){
            return true;
        }

        if(!state.apis.ping.shouldPing) return false;
    }
});