//React, React-> Component, ReactDOM
import React from 'react';
import * as ReactDOM from 'react-dom';
//React Router DOM
import { BrowserRouter as Router } from 'react-router-dom';
//Redux
import {Provider} from 'react-redux';
//Main App Component
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import storeToolkit from "./redux/store";

ReactDOM.render(
    <Provider store={storeToolkit}>
        <Router>
            <App/>
        </Router>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();