import axios from 'axios';
import NIApiAbstract from "./Contracts/Abstracts/NIApiAbstract";
import {IPingRequest, IPingSuccessResponse} from "../typization/commonTypesInterfaces";

class NIPinger extends NIApiAbstract {

    protected readonly endpoint =  '/ping.php';

    protected response = {} as IPingSuccessResponse;

    public async handle(request: IPingRequest): Promise<IPingSuccessResponse> {

        try {

            let params = JSON.stringify(request);

            const {data}  = await axios.post(this.baseUrl + this.endpoint, params);

            this.response = data;

            return this.response;
        } catch(error){

            if (error.isAxiosError){
                console.log('nipinger error', error);
                throw error;
            }

            throw Error('code error');
        }


    }

    protected persistToLocalStorage(): void {

        try {
            //console.log('ping response', this.response);
            localStorage.setItem('settings',JSON.stringify(this.response.settings));
            localStorage.setItem('customs',JSON.stringify(this.response.customs));
            if(this.response.home){
                localStorage.setItem('home',JSON.stringify(this.response.home));
            }
            if(this.response.screenSaver){
                localStorage.setItem('screenSaver',JSON.stringify(this.response.screenSaver));
            }

        } catch (error){
            //console.log('failed localStorage save', error);
        }
    }






}

export default NIPinger;

