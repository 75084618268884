import * as React from 'react';
import Button from '../Base/Button';
import commonCopy from "../../../lang/it/common";

interface BackHomeButtonInterface {
    onBtnPressed: VoidFunction;
    className?: string;
}

const BackHomeButton: React.FC<BackHomeButtonInterface> = ({onBtnPressed, className = ''}) => (
    <Button className={'back-home ' + className} onBtnPressed={onBtnPressed} label={commonCopy.exit.uppercase}/>
);

export default BackHomeButton;