import React from 'react';
import Modal from 'react-bootstrap4-modal';
import Button from "../Base/Button";
import {withRouter} from "react-router-dom";
import CloseModalIcon from "./CloseModalIcon";

const ModalConfigurationInstructions = ({isOpen, dismissModal, ofLogoImg, deviceId}) => {

    return (
        <Modal
            visible={isOpen}
            onClickBackdrop={dismissModal}
            dialogClassName={'modal-configuration-instructions modal-lg modal-dialog modal-dialog-centered base-radius'}
            disablebuttons={"false"}
            fade={true}
        >
            <CloseModalIcon onClicked={dismissModal} className={'position-absolute top-right'}/>

            <div className="modal-body text-center">
                <div className={'my-5'}>
                    <img src={ofLogoImg} alt={''} style={{width: '250px', margin: '0 auto'}}/>
                </div>

                <h3 className="font-weight-bold text-center">Dispositivo Associato correttamente</h3>

                <p className={'mb-5'}>In attesa di essere abbinato ad un defunto</p>

                <div className={' my-5 w-75 mx-auto'}>
                    <p>Per procedere all'abbinamento seguire questi semplici passaggi:</p>

                    <ol>
                        <li>effettuare il login sul portale Necrologi italia attraverso un altro dispositivo (pc o smartphone);</li>
                        <li>dal pannello di controllo, cliccare sull'icona "lista tablet kiosk";</li>
                        <li>cliccare sul dispositivo "online" con il nome di  "{deviceId}":</li>
                        <li>selezionare dal menu a tendina il defunto da associare ed uscire salvando la scelta.</li>
                    </ol>

                    <p>Trascorso appena qualche secondo, questo tablet sarà abilitato a gestire informazioni sul defunto selezionato.</p>

                </div>
                <Button
                    type={'button'}
                    className={'bg-standard-violet p-2 text-white mb-30 base-radius text-uppercase'}
                    onBtnPressed={dismissModal}
                >CHIUDI</Button>
            </div>

        </Modal>
    );
};

export default withRouter(ModalConfigurationInstructions);