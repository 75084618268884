

export const HOME_ROUTE : string = '/';

export const LOGIN_ROUTE : string = '/login';

export const MESSAGES_ROUTE : string  = '/messages';

export const SCREENSAVER_ROUTE : string = '/screensaver';

export type RedirectableRoute  = '/' | '/screensaver';

export type AppDrivenRoute = typeof LOGIN_ROUTE | typeof SCREENSAVER_ROUTE | typeof HOME_ROUTE;

export type NILocationDescriptor = {from: {pathname: RedirectableRoute}};
