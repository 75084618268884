import {NIEndpoint, NIRequest, NISuccessResponse} from "../../../typization/commonTypesInterfaces";

abstract class NIApiAbstract {

   //Properties
   protected readonly baseUrl = process.env.REACT_APP_NI_BASE_URL;
   protected readonly endpoint! : NIEndpoint;
   protected response = {} as NISuccessResponse;

   //Methods
   public abstract handle(request: NIRequest): Promise<NISuccessResponse>;

   protected abstract persistToLocalStorage():void;

}

export default NIApiAbstract;