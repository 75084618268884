import React, {ReactElement, useEffect, useState} from 'react';
import CardNecrologioDetail from '../Base/CardNecrologioDetail';
import ChurchIcon from "../../Images/Icons/ChurchIcon";
import MessageToFamilyIcon from "../../Images/Icons/MessageToFamilyIcon";
import FuneralIcon from "../../Images/Icons/FuneralIcon";
import AnnouncementIcon from "../../Images/Icons/AnnouncementIcon";
import {INormalizedDetail} from "../../../../typization/commonTypesInterfaces";

interface ChildrenProps {
    Icon : () => ReactElement;
    infoTitle: string;
    detail : string;
    textExcerpt : string;
}
interface CardNecrologioHomeDetailPropsInterface {
    className? : string;
    iconActive: boolean;
    detail : INormalizedDetail;
    children : (Icon : () => ReactElement, infoTitle: string, detail : string, textExcerpt : string, typology: string) => ReactElement<ChildrenProps,any>;
}

const CardNecrologioHomeDetail : React.FC<CardNecrologioHomeDetailPropsInterface> = ({className = '', detail, iconActive,  children}) => {

    let typology = detail.name;
    let detailFullText = detail.text;

    const iconByTypology : {[key: string] : any} = {
        'church':  () => <FuneralIcon className={'img-fluid'} isActive={iconActive}/>,
        'family': () => <MessageToFamilyIcon className={'img-fluid'} isActive={iconActive}/>,
        'funeral': () => <ChurchIcon className={'img-fluid  mb-4'} isActive={iconActive}/>,
        'announcement':  () => <AnnouncementIcon className={'img-fluid'} isActive={iconActive}/>,
    };

    const infoTitlelByTypology : {[key:string] : string} = {
        'church': 'Destinazione salma',
        'family': 'Pensiero della Famiglia',
        'funeral': 'Cerimonia Funebre',
        'announcement': 'Ne danno annuncio',
    };

    let Icon : any = null;
    let infoTitle = '';
    if (typology in iconByTypology && typology in infoTitlelByTypology){
        Icon = iconByTypology[typology];
        infoTitle = infoTitlelByTypology[typology];
    }

    let textExcerpt = useExcerpt(detailFullText, 50);

    return (
        <CardNecrologioDetail className={className}>
            {children(Icon,infoTitle, detailFullText, textExcerpt, typology )}
        </CardNecrologioDetail>
    );
};

export default CardNecrologioHomeDetail;

const useExcerpt = (text: string, maxChars : number) => {

    let [excerpt, setExcerpt ] = useState(text);

    useEffect(() => {

        if([...text].length >= maxChars){

            setExcerpt([...text].slice(1,maxChars).join("") + '...');
        }
        setExcerpt(text) ;

    },[text, maxChars ]);

    return excerpt as string;
};