import React from 'react';
import Button from '../Base/Button';
import SendYourMessageIcon from "../Images/Icons/SendYourMessageIcon";

interface LeaveAMessagePropsInteface {
    onBtnPressed: () => void;
    className? : string;
}

const LeaveAMessageButton : React.FC<LeaveAMessagePropsInteface> = ({onBtnPressed, className = ''}) => (
    <Button className={'leave-a-message ' + className} onBtnPressed={onBtnPressed} label={'ESPRIMI IL TUO CORDOGLIO'}>
        <SendYourMessageIcon />
    </Button>
);

export default LeaveAMessageButton;