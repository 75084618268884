import React from 'react';

interface FooterNecrologiPropsInterface {
    className? : string;
}

const FooterNecrologi : React.FC<FooterNecrologiPropsInterface> = ({className = '', children}) => (
    <footer className={'footer-necrologi ' + className}>
        {children}
    </footer>
);

export default FooterNecrologi;