import React from 'react';

export interface ICloseIconProps {
    className? : string;
    onClicked? : VoidFunction;
}

const CloseIcon : React.FC<ICloseIconProps> = ({className = '', onClicked = () => {}}) => (
    <img className={'icon icon-close ' + className} src={'/images/icons/dialog-close.png'} onClick={onClicked} alt={'church'}/>
);

export default CloseIcon;