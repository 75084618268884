import React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import * as Routes from "../../../routes";
import {useSelector} from "react-redux";
import {getIsAuthenticatedAndFirstPingApiCallWasPerformed} from "../../../redux/selectors/mixedSelectors";

export interface IPrivateRouteProps extends RouteProps {
    deviceId?: string;
}
const PrivateRoute : React.FC<IPrivateRouteProps> = ({ children, deviceId = '', ...routeProps }) => {

    const isAuthenticatedAndFirstPingApiCallWasPerformed = useSelector(getIsAuthenticatedAndFirstPingApiCallWasPerformed);

    return (
        <Route
            {...routeProps}
            render={({ location }) =>
                isAuthenticatedAndFirstPingApiCallWasPerformed ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.LOGIN_ROUTE,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );

};


export default PrivateRoute;
