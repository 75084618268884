import React from 'react';
import Modal from 'react-bootstrap4-modal';
import CountDown from "../Timing/CountDown";
import messagesCopy from "../../../lang/it/messages";

const ModalMessageSubmitStatus = ({isOpen, secondsToEnd,  submitSuccess, onTimeOver}) => {

    let message = submitSuccess? messagesCopy.submit.success : messagesCopy.submit.failure;

    return (
        <CountDown secondsToEnd={secondsToEnd} isCounting={isOpen} onTimeOver={onTimeOver}>
            {(timeLeft) => (
                <Modal
                    visible={isOpen}
                    onClickBackdrop={() => {console.log('no auto dismiss')}}
                    dialogClassName={'modal-message-submitted modal-md modal-dialog modal-dialog-centered base-radius'}
                    disablebuttons={"false"}
                    fade={true}
                >
                    <div className="modal-body text-center">
                        <p className={'submit-success-message my-5 '  + (submitSuccess ? 'text-standard-violet' : 'text-danger')}>
                            {message}
                        </p>
                    </div>

                </Modal>
            )}
        </CountDown>
    );
};

export default ModalMessageSubmitStatus;