import React from 'react';

export interface IFuneralIconProps {
    className? : string;
    isActive?: boolean;
}

const FuneralIcon  : React.FC<IFuneralIconProps> = ({className = '', isActive = false}) => (
    <img className={'icon icon-funeral ' + className} src={`/images/icons/funeral${isActive ? '-active' : ''}.png`} alt={'funeral'}/>
);

export default FuneralIcon;