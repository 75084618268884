import * as React from 'react';

type IconColor = 'white'| 'black';

export interface IDeleteIconProps {
    className? : string;
    onClicked? : VoidFunction;
    color?: IconColor
}

const DeleteIcon : React.FC<IDeleteIconProps> = ({className = '', onClicked = () => {}, color = 'white'}) => {

    let iconNameByColor = {
        white: 'delete-white',
        black: 'delete',
    };

    return <img className={'icon icon-delete ' + className} src={'/images/icons/' + iconNameByColor[color] + '.png'} onClick={onClicked} alt={"delete"}/>
};


export default DeleteIcon;