import React from 'react';

export interface IMessageToFamilyProps {
    className? : string;
    isActive?: boolean;
}

const MessageToFamilyIcon : React.FC<IMessageToFamilyProps> = ({className = '', isActive = false}) => (
    <img className={'icon icon-message-to-family ' + className} src={`/images/icons/message-to-family${isActive ? '-active' : ''}.png`} alt={'message-to-family'}/>
);

export default MessageToFamilyIcon;