import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {getPingApiFirstCallPerformed, nextAppPathSelector} from "./pingApiSelectors";
import {deviceIdSelector} from "./userSelectors";
import {LOGIN_ROUTE} from "../../routes";


export const getIsAuthenticatedAndFirstPingApiCallWasPerformed = createSelector(
    deviceIdSelector,
    getPingApiFirstCallPerformed,
    nextAppPathSelector,
    (deviceId,  pingApiFirstCallPerformed, nextAppPath ) => deviceId !== '' && pingApiFirstCallPerformed && nextAppPath != LOGIN_ROUTE,
);