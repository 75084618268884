import * as React from 'react';

interface AlertPropsInterface {
    typology: string;
}

const Alert: React.FC<AlertPropsInterface> = ({typology, children}) => (
    <div className={"alert alert-" + typology} role="alert">
        {children}
    </div>
);

export default Alert;
