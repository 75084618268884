import {createSlice} from "@reduxjs/toolkit";
import {authenticateUserByCredentials} from "../actions/userActions";
import {fetchPingApi} from "../actions/pingApiActions";
import {setDeviceIsOffline, setDeviceIsOnline} from "../actions/deviceStatusActions";
import {IDeviceStatus} from "../../typization/commonTypesInterfaces";

// **** SLICE STATE TYPE ******//

export type DeviceStatusSliceState = IDeviceStatus & {networkAvailable : boolean};



const getInitialState = () : DeviceStatusSliceState => {

    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
        return {...window.initialCypressState.deviceStatus}
    }

    const baseInitialState =  {
        networkAvailable :true,
        isFully: false,
        fullyId: '',
        deviceModel: '',
        imei: '',
        battery: 0.0,
        ac: 0,
    };

    try {
        //@ts-ignore
        let {fully} = window;

        if(typeof fully !== 'undefined'){

            return {
                networkAvailable :true,
                isFully: true,
                fullyId: fully.getDeviceId(),
                deviceModel: fully.getDeviceModel(),
                imei: fully.getImei(),
                battery: fully.getBatteryLevel(),
                ac: fully.isPlugged() ? 1 : 0,
            };

        }
        return baseInitialState;
    } catch (e) {

        return baseInitialState;

    }



};

// **** SLICE ******//

export const deviceStatusSlice = createSlice({
    name: 'deviceStatus',
    initialState: getInitialState() as DeviceStatusSliceState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authenticateUserByCredentials.rejected, (state, action) => {
                if(action.error.message == 'Network Error'){
                    state.networkAvailable = false;
                }
            })
            .addCase(fetchPingApi.rejected, (state, action) => {
                if(action.error.message == 'Network Error'){
                    state.networkAvailable = false;
                }
            })
            .addCase(authenticateUserByCredentials.fulfilled,(state, action) => {
                state.networkAvailable = true;
            })
            .addCase(fetchPingApi.fulfilled,(state, action) => {
                state.networkAvailable = true;
            })
            .addCase(setDeviceIsOnline.type,(state, action) => {
                state.networkAvailable = true;
            })
            .addCase(setDeviceIsOffline.type,(state, action) => {
                state.networkAvailable = false;
            })
            .addDefaultCase((state, action) => {
            })
    }
});
