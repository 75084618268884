import React from 'react';
import CardNecrologio from "./Base/CardNecrologio";
import PersonImage from "../Images/PersonImage";
import {IDeceased} from "../../../typization/commonTypesInterfaces";

interface OwlPersonCardProps {
    person: IDeceased;
}

const OwlPersonCard : React.FC<OwlPersonCardProps> = ({person}) => {

    let funeralDetail = person.details.filter((detail) => detail.name === "funeral")[0].text;

    return (
        <CardNecrologio extraContainerClasses={'screensaver-person bg-standard-grey base-radius'}>
            <div className={'card-head person text-center pt-40 pb-40 pl-15 pr-15'}>
                <div className="image-cnt mb-30">
                    <PersonImage className={'bg-white m-auto'} src={person.baseInfos.imgSrc}/>
                </div>
                <h1 className={'name'}>{person.baseInfos.name}</h1>
                <p>{funeralDetail}</p>
            </div>
        </CardNecrologio>
    )
};

export default OwlPersonCard;