import React from 'react';
import {Form, Formik} from "formik";
import InputFormikField from "../FormikFields/InputFormikField";
import PasswordInputFormikField from "../FormikFields/PasswordInputFormikField";
import * as Yup from "yup";
import {clientSideValidationErrors} from "../../Scenes/LoginScene";

const LoginFormDeviceId =  ({  getFormInitialValues, onLoginClicked, isAttemptingLogin, className = '' }) => (
    <div className={'deviceid ' + className} >

        <Formik
            initialValues={getFormInitialValues('device-id')}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={ Yup.object().shape({
                username: Yup.string()
                    .email(clientSideValidationErrors.username.notValidEmail)
                    .required(clientSideValidationErrors.username.required),
                password: Yup.string()
                    .min(6, clientSideValidationErrors.password.length.min)
                    .required(clientSideValidationErrors.password.required),
                deviceId: Yup.string()
                    .required(clientSideValidationErrors.deviceId.required)
                    .test('len', clientSideValidationErrors.deviceId.length, val => val !== undefined ? val.length === 16 : false )
            })}
            onSubmit={(values, formikHelpers) => {
              let normalizedValues = {
                    username: values.username.toLowerCase(),
                    password: values.password,
                    deviceId: values.deviceId.toLowerCase(),
                };

                onLoginClicked(normalizedValues);
            }}
        >
            {(formikProps) => (
                <Form className={`login-form ${className} ${isAttemptingLogin ? 'd-none' : ''}`} >


                    <h5 className={'text-center font-weight-bold mt-35'}>Se possiedi già un device id:</h5>

                    <p className={'instructions mt-15'}>
                        Questo codice è da inserire solamente nel caso in cui stai sostituendo un vecchio tablet
                        con questo nuovo dispositivo.
                        <br/>
                        Sul portale Necrologi Italia, nella sezione dedicata a Kiosk, troverai il vecchio DeviceID, da associare
                        a questo nuovo tablet
                    </p>

                    <InputFormikField name={'username'} values={formikProps.values} errors={formikProps.errors} touched={formikProps.touched} withLabel={false} placeholder={'Email'}/>

                    <PasswordInputFormikField name={'password'} values={formikProps.values} errors={formikProps.errors} touched={formikProps.touched} withLabel={false} placeholder={'Password'}/>

                    <InputFormikField name={'deviceId'} values={formikProps.values} errors={formikProps.errors} touched={formikProps.touched} withLabel={false} placeholder={'Device ID'}/>

                    <div className="form-group text-center mt-35">
                        <button type='submit' className={'button pt-5 pb-5 w-75 h-auto btn-login bg-standard-violet base-radius text-white text-uppercase font-weight-normal'}>ASSOCIA</button>
                    </div>

                </Form>
            )}
        </Formik>
    </div>
);

export default LoginFormDeviceId;