import {AppDrivenRoute, HOME_ROUTE, SCREENSAVER_ROUTE} from "./routes";
import {IPingSuccessResponse} from "./typization/commonTypesInterfaces";

export const getNextAppPathByPingResponse = (response : IPingSuccessResponse) : AppDrivenRoute => {

    if('screenSaver' in response){
        return SCREENSAVER_ROUTE;
    }
    if ('home' in response){
        return HOME_ROUTE;
    }

    return '';

};