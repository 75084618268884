import * as React from 'react';
import ErrorsAlert from "../Presentational/Auth/ErrorsAlert";
import NecrologiItaliaLogo from "../Presentational/Images/NecrologiItaliaLogo";
import LoginFormUserCredentials from "../Presentational/Auth/LoginFormUserCredentials";
import LoadingCircles from "../Presentational/Images/Icons/LoadingCircles";
import {LoginMethodChoice} from "../Presentational/Auth/LoginMethodChoiche";
import LoginFormDeviceId from "../Presentational/Auth/LoginFormDeviceId";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authenticateUserByCredentials} from "../../redux/actions/userActions";
import {unwrapResult} from "@reduxjs/toolkit";
import {fetchPingApi} from "../../redux/actions/pingApiActions";
import {RootState} from "../../redux/store";
import {INICredentials} from "../../typization/commonTypesInterfaces";

export const clientSideValidationErrors = {
    username: {
        notValidEmail :'Username non valida',
        required: 'Username richiesta',
    },
    password: {
        length: {
            min: 'Password deve avere almeno 6 caratteri',
        },
        required: 'Password richiesta',
    },
    deviceId: {
        required: 'Device ID richiesto',
        length: 'Device ID deve essere di 16 caratteri '
    }
};

type LoginFormsValues = {
    username: string;
    password: string;
    deviceId: string;
}

interface ILoginSceneProps {

}

const LoginScene : React.FC<ILoginSceneProps> = (props) => {
    
    const dispatch = useDispatch();

    const authApiError = useSelector((state : RootState ) => state.apis.init.error);
    const deviceId = useSelector((state:RootState) => state.user.deviceId);

    const [isAttemptingLogin, setIsAttemptingLogin ] = useState<boolean>(false);
    const [loginMethod, setLoginMethod] = useState<'user-credentials' | 'deviceid' | ''>('');


    const onLoginClicked : (fields: INICredentials) => void = async (fields) => {
         if (isAttemptingLogin) return;

         setIsAttemptingLogin(true);

         try {
            const resultAuthAction = await dispatch(authenticateUserByCredentials(fields));
            //@ts-ignore
            const hasLoggedIn = unwrapResult(resultAuthAction);

         } catch(e){

            setIsAttemptingLogin(false);
            return;
         }

         try {

             const resultPingAction = await dispatch(fetchPingApi({}));

             //@ts-ignore
             const pingSuccessData = unwrapResult(resultPingAction);

             setIsAttemptingLogin(false);

         } catch (e) {
             setIsAttemptingLogin(false);
         }

    };

    const getFormInitialValues : (method: 'user-credentials' | 'deviceid') => INICredentials = (method) : LoginFormsValues =>  {
         let values = {
             username: '',
             password: '',
             deviceId: '',
         };
         if(method === 'user-credentials') return {
             ...values,
             deviceId: deviceId !== null && deviceId !== undefined ? deviceId : ''
         };
         return values;
    };

   const onLoginMethodPicked = (method: 'user-credentials' | 'deviceid') => {
        setLoginMethod(method);
    };

    const resetLoginMethodChoiche = () => {
       setLoginMethod('');
    };

    let errorsContent = authApiError !== '' ? <ErrorsAlert className={'mt-15'} errors={[authApiError]}/> : null;

    return (
            <div className="container-fluid" id="login-scene">

                <div className="row h-100">

                    <div className="col-4 offset-sm-1 welcome pt-30 pb-15 d-flex flex-column justify-content-between">

                        <div className="logo text-center">
                            <NecrologiItaliaLogo className={'w-50'}/>
                        </div>

                        <div className="intro">

                            <h1 className={'welcome-claim text-standard-violet'}>Benvenuto</h1>

                            <p className={'bind-device mb-15 text-justify'}>Associa questo dispositivo al portale Necrologi Italia.</p>

                            <p className={'usage mb-60 text-justify'}>
                                Il servizio ti permetterà di raccogliere firme e pensieri dei partecipanti al lutto,
                                e di realizzare un elegante libro firme digitali da consegnare alla famiglia.
                            </p>

                            <p className={'visit-site mb-45 text-justify'}>
                                Per informazione sull'uso e la configurazione di questo dispositivo visita
                                dal tuo pc o smartphone:
                                <br />
                                <span className={'d-block mt-10'}>https://www.necrologi-italia/kiosk.php</span>
                            </p>

                        </div>


                    </div>

                    <div className="col-4 offset-sm-2 login-area pt-70 pb-15">

                        <h1 className={'kiosk-title text-standard-violet text-uppercase mb-35 font-weight-normal text-center'}>KIOSK</h1>

                        <LoginMethodChoice activeLoginMethod={loginMethod} onLoginMethodPicked={onLoginMethodPicked}/>

                        <div className={'login-form ' + (loginMethod === '' && 'd-none') }>

                            <LoginFormUserCredentials
                                getFormInitialValues={getFormInitialValues}
                                isAttemptingLogin={isAttemptingLogin}
                                className={`${loginMethod !== 'user-credentials' ? 'd-none' : ''}`}
                                onLoginClicked={onLoginClicked}
                            />
                            <LoginFormDeviceId
                                getFormInitialValues={getFormInitialValues}
                                isAttemptingLogin={isAttemptingLogin}
                                className={`${loginMethod !== 'deviceid' ? 'd-none' : ''}`}
                                onLoginClicked={onLoginClicked}
                            />

                            <button
                                type='button'
                                onClick={resetLoginMethodChoiche}
                                className={'button d-block pt-5 pb-5 w-75 h-auto bg-darker-grey base-radius text-dark text-uppercase font-weight-normal mx-auto'}>TORNA ALLA SCELTA</button>

                            {isAttemptingLogin ? <LoadingCircles className={'login-scene'}/> : null}

                            {errorsContent}

                        </div>

                    </div>

                </div>

            </div>
    );

};

export default LoginScene;
