import * as React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import OwlPersonCard from "../Presentational/Cards/OwlPersonCard";
import {useState} from "react";
import ModalConfigurationInstructions from "../Presentational/Modals/ModalConfigurationInstructions";
import {ICustom, IDeceased} from "../../typization/commonTypesInterfaces";

interface ScreenSaverSceneProps {
    persons : IDeceased[];
    showContent: boolean;
    deviceId: string;
    customs:ICustom ;
}

const ScreenSaverScene : React.FC<ScreenSaverSceneProps> = ({persons, showContent, deviceId, customs }) =>  {

    const [isInstructionsModalOpen, setIsIntructionsModalOpen] = useState(true);

    const dismissModal = () => {setIsIntructionsModalOpen(false)};

    if(!showContent ) return <p>FetchingData</p>;

    console.log('rerender screensaver scene', persons);

    if (persons.length === 0 ) return (<p>Nessun defunto da mostrare</p>);

    let carouselItems = persons.map(((person, idx) => <OwlPersonCard key={idx} person={person}/>));

    return   (
        <>
            <div id="screensaver-scene" className={'container-fluid d-flex flex-column justify-content-center bg-standard-violet'}>
                {

                    <OwlCarousel items={3}  margin={10} loop autoplay={true} autoplayTimeout={2000} >
                        {carouselItems}
                    </OwlCarousel>
                }

            </div>
            <ModalConfigurationInstructions isOpen={isInstructionsModalOpen} dismissModal={dismissModal} ofLogoImg={customs.logo} deviceId={deviceId}/>
        </>
    );
};

const MemoizedScreensaverScene = React.memo(ScreenSaverScene, (prevProps, nextProps) => {

    //checking new pinger call sent a different array of persons
    //necessary to avoid unnecessary rerendering of the carousel causing it to suddendly restart(bad ui).
    let samePersonsArray = prevProps.persons.every((person, index) => person.id ===  nextProps.persons[index].id);

    //showing array ids just for loggin purposes
    /*
    let prevPropsPersonsIds = prevProps.persons.map(person => person.id);
    let nextPropsPersonsIds = nextProps.persons.map(person => person.id);
    console.log('persons ids are equal', samePersonsArray, nextPropsPersonsIds, prevPropsPersonsIds);
    */
    return samePersonsArray && prevProps.showContent === nextProps.showContent;
});

export default MemoizedScreensaverScene;