import axios from 'axios';
import NIApiAbstract from "./Contracts/Abstracts/NIApiAbstract";
import {ILoginSuccessResponse, INICredentials} from "../typization/commonTypesInterfaces";

class NIAuthenticator extends NIApiAbstract {

    protected readonly endpoint =  '/init.php';

    protected response : ILoginSuccessResponse = {} as ILoginSuccessResponse;

    public async handle(loginData : INICredentials) : Promise<ILoginSuccessResponse> {

        try {

        let params = JSON.stringify(loginData);

        const {data}  = await axios.post(this.baseUrl + this.endpoint, params);

        this.response = data;
        if('deviceId' in data && 'customs' in data){
            this.persistToLocalStorage();
        }

        return this.response;
        } catch(error){

            if (error.isAxiosError){
                console.log('niAuthenticator error', error);
                throw error;
            }

            throw Error('code error');
        }

    }

    protected persistToLocalStorage(): void {

        try {
            localStorage.setItem('deviceId', this.response.deviceId);
            localStorage.setItem('customs', JSON.stringify(this.response.customs));

        } catch (error){

            //console.log('failed localStorage save', error);
        }
    }

}

export default NIAuthenticator;

