import React from 'react';
import CardNecrologio from '../Base/CardNecrologio';
import PersonImage from '../../Images/PersonImage';
import CardNecrologioHomeDetail from "./CardNecrologioHomeDetail";
import {IDetailsRaw, INormalizedDetail, INormBaseInfos} from "../../../../typization/commonTypesInterfaces";
import CardNecrologioDetail from "../Base/CardNecrologioDetail";
import ManifestIcon from "../../Images/Icons/ManifestIcon";
import {useSelector} from "react-redux";
import {
    selectedDeceasedAssociationSelector,
    selectedDeceasedManifestSelector
} from "../../../../redux/selectors/selectedDeceasedSelector";
import AssociationIcon from "../../Images/Icons/AssociationIcon";

interface CardNecrologioHomePropsInterface {
    className?: string;
    baseInfos: INormBaseInfos;
    details: INormalizedDetail[];
    onDetailClick: (name: keyof IDetailsRaw) => void;
}

const CardNecrologioHome: React.FC<CardNecrologioHomePropsInterface> = ({className = '', baseInfos, details, ...props}) => {

    const selectedDeceasedAssociation = useSelector(selectedDeceasedAssociationSelector);
    const selectedDeceasedManifest = useSelector(selectedDeceasedManifestSelector);

    const associationVisibilityClass = !selectedDeceasedAssociation.id && !selectedDeceasedAssociation.text ? 'd-none' : '';
    const manifestVisibilityClass = selectedDeceasedManifest.thumb != "" ? '' : 'd-none';

    return (
        <CardNecrologio
            extraContainerClasses={`home-card bg-standard-grey h-100 d-flex flex-column justify-content-between ${className}`}>
            <div className={'card-head person text-center pt-15 pl-15 pr-15'}>
                <div className="image-cnt mb-15">
                    <PersonImage className={'card-home bg-white'} src={baseInfos.imgSrc}/>
                </div>
                <h1 className={'name'}>{baseInfos.name}</h1>
                <h4 className={'born'}>{baseInfos.birth}</h4>
                <h4 className={'death'}>{baseInfos.death}</h4>
                <h4 className={'married'}>{baseInfos.married}</h4>
            </div>
            <div className="container card-body details-container">
                <div className="row justify-content-center">
                    {details.map((detail: INormalizedDetail, index: number) => (
                        <div className="col-6 cursor-pointer" key={index} onClick={() => {
                            props.onDetailClick(detail.name)
                        }}>
                            <CardNecrologioHomeDetail detail={detail} iconActive={false}>
                                {(Icon, infoTitle) => (
                                    <React.Fragment>
                                        <Icon/>
                                        <p className="detail-text">{infoTitle}</p>
                                    </React.Fragment>
                                )}
                            </CardNecrologioHomeDetail>
                        </div>
                    ))}
                    <div className={"col-6 cursor-pointer " + associationVisibilityClass} onClick={() => props.onDetailClick('association')}>
                        <CardNecrologioDetail className={'association'}>
                            <AssociationIcon isActive={false}/>
                            <p className="detail-text mt-3 mb-0">{'Donazione'}</p>
                        </CardNecrologioDetail>
                    </div>
                    <div className={"col-6 cursor-pointer " + manifestVisibilityClass} onClick={() => props.onDetailClick('manifest')}>
                        <CardNecrologioDetail className={'manifest'}>
                            <ManifestIcon isActive={false}/>
                            <p className="detail-text mt-3 mb-0">{'Manifesto'}</p>
                        </CardNecrologioDetail>
                    </div>
                </div>
            </div>
        </CardNecrologio>
    )
};

export default CardNecrologioHome;