import React from 'react';

interface CardNecrologioDetailPropsInterface {
  className? : string;
}

const CardNecrologioDetail : React.FC<CardNecrologioDetailPropsInterface>= ({className = '', children}) => (
  <div className={"card-detail " + className}>
      {children}
  </div>
);

export default CardNecrologioDetail;