import {createSlice} from "@reduxjs/toolkit";
import {fetchPingApi, pingApiSetShouldPing, setPingApiTiming} from "../actions/pingApiActions";
import {authenticateUserByCredentials, setUserIsNotEnabled} from "../actions/userActions";
import {getNextAppPathByPingResponse} from "../../utils";
import {AppDrivenRoute, HOME_ROUTE, LOGIN_ROUTE} from "../../routes";
import {ICustom, NISuccessResponse} from "../../typization/commonTypesInterfaces";

// **** SLICE STATE TYPE ******//

export type PingApiSliceState = {
    firstCall: 1 | 0;
    isLoading: boolean;
    shouldPing: boolean;
    nextAppPath: AppDrivenRoute;
    settings: {
        timing : number
    };
    error: string;
}

const getInitialState = () : PingApiSliceState => {

    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
        return {...window.initialCypressState.apis.ping}
    }

    const baseInitialState : PingApiSliceState =  {
        firstCall: 1,
        isLoading: false,
        shouldPing: false,
        nextAppPath: HOME_ROUTE,
        settings: {
            timing: 30,
        },
        error: '',
    };
    try {

        //redux stored data
        const storedPingApiData : string | null = localStorage.getItem('ping_api');

        if(storedPingApiData && storedPingApiData !== "undefined"){
            let parsedPingApiData : PingApiSliceState = JSON.parse(storedPingApiData);
            if('settings' in parsedPingApiData){
                return {...baseInitialState, ...parsedPingApiData}
            }
        }

        //retrocompatibility
        let storedSettings : string | null = localStorage.getItem('settings');

        if (storedSettings && storedSettings !== "undefined"  ){
            let parsedSettings : ICustom = JSON.parse(storedSettings);
            if ('pingTiming' in parsedSettings ){
                localStorage.removeItem('settings');
                return {...baseInitialState, settings : {timing: parsedSettings['pingTiming']}};
            }
        }

        return baseInitialState;

    } catch (e) {
        return baseInitialState;
    }

};

// **** SLICE ******//

export const pingApiSlice = createSlice({
    name: 'ping',
    initialState: getInitialState() as PingApiSliceState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(authenticateUserByCredentials.fulfilled, (state, action) => {
                state.shouldPing = true;
            })
            .addCase(fetchPingApi.pending, state => {
                state.isLoading = true;
                state.error = '';
            })
            .addCase(fetchPingApi.fulfilled, (state, action) => {
                state.firstCall =  0;
                state.isLoading = false;
                state.error = '';
                if(action.payload?.settings?.pingTiming){
                    state.settings.timing = action.payload.settings.pingTiming;
                }
                state.nextAppPath = getNextAppPathByPingResponse(action.payload as NISuccessResponse);
            })
            .addCase(fetchPingApi.rejected, (state, action) => {
                state.firstCall =  state.firstCall === 0 ? 0  : 1;
                state.isLoading = false;
                state.error = action.payload as string || action.error.message!;
            })
            .addCase(pingApiSetShouldPing, (state, action) => {
                state.shouldPing = action.payload;
            }).addCase(setPingApiTiming, (state, action) => {
                state.settings.timing = action.payload;
            })
            .addCase(setUserIsNotEnabled.type,(state, action) => {
                state.nextAppPath = LOGIN_ROUTE;
                state.shouldPing = false;
            })
            .addDefaultCase((state, action) => {

            })
    }
});
