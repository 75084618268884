import {createSlice} from "@reduxjs/toolkit";
import {authenticateUserByCredentials, setUserIsNotEnabled} from "../actions/userActions";
import {fetchPingApi} from "../actions/pingApiActions";

// **** SLICE STATE TYPE ******//

export type UserSliceState = {
    deviceId: string;
    isEnabled: boolean;
}

const getInitialState = () : UserSliceState => {

    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
        return {...window.initialCypressState.user}
    }

    const baseInitialState = {
        deviceId: '',
        isEnabled: true
    };

    try {

        //new redux + retrocompatibility
        const storedDeviceId = localStorage.getItem('deviceId');
        if(storedDeviceId && storedDeviceId !== "undefined"){
            return {...baseInitialState, deviceId: storedDeviceId};
        }

        return baseInitialState;

    } catch (e) {
        return baseInitialState;
    }

};

// **** SLICE ******//

export const userSlice = createSlice({
    name: 'user',
    initialState: getInitialState() as UserSliceState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authenticateUserByCredentials.fulfilled, (state, action) => {
                const deviceId = action.payload?.deviceId;
                if(deviceId !== null && deviceId !== undefined && deviceId !== ''){
                    state.deviceId = action.payload.deviceId;
                }
            })
            .addCase(setUserIsNotEnabled.type,(state, action) => {
                state.deviceId = '';
                state.isEnabled = false;
            })
            .addCase(fetchPingApi.fulfilled,(state, action) => {
                state.isEnabled = true;
            })
            .addDefaultCase((state, action) => {
            })
    }
});
