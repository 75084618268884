import React from 'react';

export interface IManifestIconProps {
    className? : string;
    isActive?: boolean;
}

const ManifestIcon : React.FC<IManifestIconProps> = ({className = '', isActive = false}) => (
    <img className={'icon icon-manifest' + className} src={`/images/icons/manifest${isActive ? '-active' : ''}.png`} alt={'manifest'}/>
);

export default ManifestIcon;