import React from 'react';
import Modal from 'react-bootstrap4-modal';
import Button from "../Base/Button";
import {withRouter} from "react-router-dom";
import CountDown from "../Timing/CountDown";
import CloseModalIcon from "./CloseModalIcon";

const ModalConfirmActivity = ({isOpen, secondsToEnd, dismissModal, onTimeOver, ofLogoImg}) => {

    return (
        <CountDown secondsToEnd={secondsToEnd} isCounting={isOpen} onTimeOver={onTimeOver}>
            {(timeLeft) => (
                <Modal
                    visible={isOpen}
                    onClickBackdrop={dismissModal}
                    dialogClassName={'modal-confirm-activity modal-lg modal-dialog modal-dialog-centered base-radius'}
                    disablebuttons={"false"}
                    fade={true}
                >
                    <CloseModalIcon onClicked={dismissModal} className={'position-absolute top-right'}/>

                    <div className="modal-body text-center">
                        <img src={ofLogoImg} alt={''} style={{width: '250px', margin: '0 auto'}}/>
                        <p className={'still-here my-5'}>
                            Stai ancora scrivendo un messaggio? <br/>
                            Conferma la tua presenza o torneremo alla pagina principale in {timeLeft} secondi
                        </p>
                        <Button type={'button'} className={'bg-standard-violet p-2 text-white mb-30 base-radius text-uppercase'} onBtnPressed={dismissModal}>Sono ancora qui</Button>
                    </div>

                </Modal>
            )}
        </CountDown>

    );
};

export default withRouter(ModalConfirmActivity);