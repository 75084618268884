import * as React from 'react';

interface ButtonPropsInterface {
    className? : string;
    label? : string;
    onBtnPressed? : () => void;
    type?: 'button' | 'submit'| 'reset';
}

const Button : React.FC<ButtonPropsInterface>= ({className = '', label, onBtnPressed = () =>{}, type = 'button', children = null}) => {
    let onClickProp = type === 'submit' ? {} : {onClick : onBtnPressed};

    return <button type={type} className={'button ' + className} {...onClickProp}>{label}{children}</button>
};



export default Button;