import React, {useState} from 'react';
import styled from "styled-components/macro";
import {ErrorMessage, Field, Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import messagesCopy from "../../lang/it/messages";
import InputFormikField from "./FormikFields/InputFormikField";
import Button from "./Base/Button";
import AnnouncementIcon from "./Images/Icons/AnnouncementIcon";

export interface IThanksgivingFormValues {
    address: string;
    email: string;
    mobile: string;
    channel : 'scegli' | 'whatsapp' | 'sms' | 'telegram';
}

export interface IThanksgivingFormProps {
    className?: string;
    onSubmit: (values: IThanksgivingFormValues) => void;
}

export const ThanksgivingForm: React.FC<IThanksgivingFormProps> = ({className = '', ...props}) => {

    const thanksgivingCopy = messagesCopy.form.thanksgiving;

    const validationSchema = Yup.object().shape({
            address: Yup.string().notRequired(),
            email: Yup.string()
                .notRequired()
                .email(thanksgivingCopy.validation.email.invalid),
            mobile: Yup.string()
                .notRequired()
                .when('channel', {
                    is: (channel) => channel && channel != 'scegli',
                    then: Yup.string()
                        .required(thanksgivingCopy.validation.mobile.required)
                        .min(10, thanksgivingCopy.validation.mobile.length)
                        .max(10, thanksgivingCopy.validation.mobile.length)
                })
                .when('channel', {
                    is: (channel) => channel && channel == 'scegli',
                    then: Yup.string()
                        .notRequired()
                        .min(10, thanksgivingCopy.validation.mobile.length)
                        .max(10, thanksgivingCopy.validation.mobile.length)
                }),
            channel : Yup.string()
                .notRequired()
        });



    return (
        <Formik
            initialValues={{
                address: '',
                email: '',
                mobile: '',
                channel : 'scegli' as 'scegli' | 'whatsapp' | 'sms' | 'telegram',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {props.onSubmit(values)}}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formikProps) => {

                return (
                        <Form className={className + ' container'}>
                            <div className="row">
                                <div className="col-12 d-flex mb-3">
                                    <img className={'icon'} src={`/images/icons/address-field-icon.png`} alt={"address"}/>
                                    <InputFormikField
                                        className={'address-field flex-grow-1 ml-3 mb-0'}
                                        name={'address'}
                                        {...formikProps}
                                        withLabel={false}
                                        placeholder={thanksgivingCopy.placeholders.address}
                                        withErrorMessage={true}
                                        withErrorTooltip={false}
                                    />
                                </div>
                                <div className="col-12 d-flex mb-3">
                                    <img className={'icon'} src={`/images/icons/mail-field-icon.png`} alt={"email"}/>
                                    <InputFormikField
                                        className={'email-field flex-grow-1 ml-3 mb-0'}
                                        name={'email'}
                                        {...formikProps}
                                        withLabel={false}
                                        placeholder={thanksgivingCopy.placeholders.email}
                                        withErrorMessage={true}
                                        withErrorTooltip={false}
                                    />
                                </div>
                                <div className="col-12 d-flex mb-3">
                                    <img className={'icon'} src={`/images/icons/mobile-field-icon.png`} alt={"mobile"}/>
                                    <div className={"form-group flex-grow-1 ml-3 mb-0"}>
                                        <Field data-testid={"select-channel"} className={'form-control ' + (formikProps.errors.channel && formikProps.touched.channel ? ' is-invalid' : '')} as="select" name="channel">
                                            <option value={thanksgivingCopy.select.channels.choose.value}>{thanksgivingCopy.select.channels.choose.label}</option>
                                            <option value={thanksgivingCopy.select.channels.whatsapp.value}>{thanksgivingCopy.select.channels.whatsapp.label}</option>
                                            <option value={thanksgivingCopy.select.channels.telegram.value}>{thanksgivingCopy.select.channels.telegram.label}</option>
                                            <option value={thanksgivingCopy.select.channels.sms.value}>{thanksgivingCopy.select.channels.sms.label}</option>
                                        </Field>
                                        {formikProps.errors.channel ? <div className={'invalid-feedback'}>{formikProps.errors.channel}</div> : null}
                                    </div>
                                </div>
                                <div className="col-12 d-flex mb-3">
                                    <img className={'icon opacity-0'} src={`/images/icons/mobile-field-icon.png`} alt={"mobile"}/>
                                    <InputFormikField
                                        className={'mobile-field flex-grow-1 ml-3 mb-0'}
                                        name={'mobile'}
                                        {...formikProps}
                                        withLabel={false}
                                        placeholder={thanksgivingCopy.placeholders.mobile}
                                        withErrorMessage={true}
                                        withErrorTooltip={false}
                                    />
                                </div>
                                <div className={'col-12'}>
                                    <Button className={'bg-standard-violet base-radius text-white text-center w-100'} type={'submit'} label={thanksgivingCopy.submit} />
                                </div>
                            </div>
                        </Form>
                )
            }}
        </Formik>
    );
};

const StyledThanksgivingForm = styled(ThanksgivingForm)`
 .opacity-0 {
    opacity: 0;
 }
`;

StyledThanksgivingForm.displayName = "StyledThanksgivingForm";

export default StyledThanksgivingForm;