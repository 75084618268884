import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
//@ts-ignore
import logger from 'redux-logger';

export type RootState = ReturnType<typeof rootReducer>

const storeToolkit = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    reducer : rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
});

export default storeToolkit;