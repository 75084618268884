import React, { useRef } from 'react';
import Modal from 'react-bootstrap4-modal';
import Button from "../Base/Button";
import SignatureCanvas from "react-signature-canvas";
import PenBlackIcon from "../Images/Icons/PenBlackIcon";
import DeleteIcon from "../Images/Icons/DeleteIcon";
import messagesCopy from "../../../lang/it/messages";

interface IModalWriteSignProps {
    isOpen : boolean;
    dismissModal: VoidFunction;
    initialValue? : string;
    onFieldSubmit : (field: string, value: any, shouldValidate?: boolean) => void;
}

const ModalWriteSign : React.FC<IModalWriteSignProps> = ({isOpen, dismissModal, initialValue = '', onFieldSubmit }) => {


    const canvasRef = useRef<SignatureCanvas | undefined | null>();

    const resetCanvas = () => {
        if(canvasRef && canvasRef.current instanceof SignatureCanvas) {
            canvasRef.current.clear();
        }
    };

    const submitAndDismiss = () => {

        if(canvasRef && canvasRef.current instanceof SignatureCanvas){
            let base64Png = canvasRef.current.toDataURL();
            onFieldSubmit('base64_png', base64Png );
        }
        dismissModal();
    };

    return (
        <Modal visible={isOpen} className={'modal-write sign'} dialogClassName={'modal-xl modal-dialog modal-dialog-top'} onClickBackdrop={submitAndDismiss}>
            <div className="modal-body text-center p-5 position-relative">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="position-relative">

                            <PenBlackIcon className={'position-absolute top-left d-none'}/>
                            <h1>{messagesCopy.form.modals.titles.canvasSign}</h1>
                            <SignatureCanvas
                                ref={(ref) => {canvasRef.current = ref}}
                                penColor='blue'
                                canvasProps={{
                                    width: 1040,
                                    height: 346,
                                    className: 'react-canvas bg-white'
                                }}
                            />
                            <DeleteIcon color={'black'} onClicked={resetCanvas} className={'position-absolute top-right'}/>
                        </div>
                    </div>
                    <div className="col-12 text-center">

                            <Button
                                className={'leave-a-message bg-standard-violet base-radius text-white text-center w-50 mr-4'}
                                label={messagesCopy.form.modals.buttons.saveAndExit}
                                onBtnPressed={submitAndDismiss}
                            />

                    </div>
                </div>
            </div>
        </Modal>
    )

};

export default ModalWriteSign;