import React from 'react';

interface MessagePropsInterface {
    className? :string;
    message: {
        mittente: string;
        msg: string;
        base64_png: string;
        data: string;
        ora: string;
    };
}

const Message  : React.FC<MessagePropsInterface> = ({message, className = ''}) => {



    return (
        <div className={"row message " + className }>
            <div className="col-6 left">
                <p className={'keyboard-message'}>{message.msg}</p>
                <p className={'sender'}>{message.mittente || 'mittente test'}</p>
            </div>
            <div className="col-6 right">
                {message.base64_png ? <img src={atob(message.base64_png)} className={'signature-message img-fluid'} alt="noImage"/> : null}
            </div>
        </div>
    );
};

export default Message;