import {RootState} from "../store";

export const selectedDeceasedSelector = (state: RootState) => state.selectedDeceased;

export const selectedDeceasedMessagesSelector = (state: RootState) => state.selectedDeceased.messages;

export const selectedDeceasedDetailsSelector = (state: RootState) => state.selectedDeceased.details;

export const selectedDeceasedAssociationSelector = (state: RootState) => state.selectedDeceased.association;

export const selectedDeceasedManifestSelector = (state: RootState) => state.selectedDeceased.manifest;