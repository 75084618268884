import React from 'react';
import Modal from 'react-bootstrap4-modal';
import Iframe from "react-iframe";
import CloseModalIcon from "./CloseModalIcon";

const ModalPrivacy = ({isOpen, dismissModal}) => (

    <Modal visible={isOpen} className={'modal-privacy'} dialogClassName={'modal-xl modal-dialog modal-dialog-centered'} onClickBackdrop={dismissModal}>
        <Iframe url="https://www.necrologi-italia.it/privacy_kiosk.php"
                id="privacy-iframe"
                className="h-100"
                display="block"
                scrolling={'auto'}

        />
        <CloseModalIcon onClicked={dismissModal}  className={'position-absolute top-left'}/>
    </Modal>

);

export default ModalPrivacy;