import React from 'react';
import {Field, ErrorMessage} from 'formik';

const PasswordInputFormikField  = ({ errors, touched, name, type = 'password',placeholder = '', label = '', withLabel = true}) => (

    <div className="form-group form-label-group">
        {withLabel ? <label htmlFor={name}>{label}</label> : null}
        <Field name={name} type={type} className={'form-control' + (errors[name] && touched[name] ? ' is-invalid' : '')} placeholder={placeholder}/>
        <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>

);

export default PasswordInputFormikField;