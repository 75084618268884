import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../store";

export const deviceIdSelector = (state:RootState) => state.user.deviceId;

export const getIsAuthenticated = createSelector(
    deviceIdSelector,
    (deviceId) => deviceId !== ''
);

