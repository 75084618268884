import React from 'react';
import styled from "styled-components";
import useEventListener from "@use-it/event-listener";
import {setDeviceIsOffline, setDeviceIsOnline} from "../../../redux/actions/deviceStatusActions";
import {useDispatch, useSelector} from "react-redux";
import {deviceIsOnlineSelector} from "../../../redux/selectors/deviceStatusSelectors";

export interface IBannerDeviceOfflineProps {
    className?: string;
}

export const BannerDeviceOffline: React.FC<IBannerDeviceOfflineProps> = ({className = '', ...props}) => {

    const dispatch = useDispatch();
    const deviceIsOnline = useSelector(deviceIsOnlineSelector);

    useEventListener('offline', () => {
        dispatch(setDeviceIsOffline());
    });

    useEventListener('online', () => {
        dispatch(setDeviceIsOnline());
    });

    return (
        <div className={`${className} banner-device-offline bg-standard-violet ${deviceIsOnline ? 'd-none' : ''}`}>
            <div className="feedback">
                <p className={'offline'}>dispositivo offline</p>
                Controlla la tua connessione. Una volta riconnesso questo banner scomparirà.
            </div>
        </div>
    );
};

const StyledBannerDeviceOffline = styled(BannerDeviceOffline)`
  
  border: 3px solid white;
  position: fixed;
  padding: 15px;
  bottom: 0;
  width: 100%;  
  z-index: 100;
  text-align: center;
  border-radius: 6px;
  
  .feedback  {
  font-size: 15px;
  font-weight: bold;
  .offline {
    font-size: 20px;
    text-transform: uppercase;
  }
  &, &.offline {
        color: white;
    }
  }
`;

StyledBannerDeviceOffline.displayName = "StyledBannerDeviceOffline";

export default StyledBannerDeviceOffline;