import React from 'react';
import CloseIcon from "../Images/Icons/CloseIcon";

interface ICloseModalIconProps {
    className?: string;
    onClicked: VoidFunction;
}

const CloseModalIcon : React.FC<ICloseModalIconProps> = ({onClicked, className = '' }) => {
    return <CloseIcon onClicked={onClicked}  className={'modal ' + className}/>
};

export default CloseModalIcon;