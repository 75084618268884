import React from 'react';

export interface IAssociationIconProps {
    className? : string;
    isActive?: boolean;
}

const AssociationIcon : React.FC<IAssociationIconProps> = ({className = '', isActive = false}) => (
    <img className={'icon icon-association' + className} src={`/images/icons/association${isActive ? '-active' : ''}.png`} alt={`association${isActive ? '-active' : ''}`}/>
);

export default AssociationIcon;