import React from 'react';

interface CardNecrologioPropsInterface {
  extraContainerClasses? : string;
}

const CardNecrologio : React.FC<CardNecrologioPropsInterface> = ({extraContainerClasses = '', children}) => (
  <div className={"card-necrologio " + extraContainerClasses}>
      {children}
  </div>
);

export default CardNecrologio;