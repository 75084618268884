import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import NIAuthenticator from "../../apis/NIAuthenticator";
import {RootState} from "../store";
import commonCopy from "../../lang/it/common";
import {INICredentials} from "../../typization/commonTypesInterfaces";

export const authenticateUserByCredentials = createAsyncThunk('AUTHENTICATE_USER',
async (credentials : INICredentials, { getState, rejectWithValue, dispatch}) => {

const NIAuthInstance = new NIAuthenticator();

try {
        const response =  await NIAuthInstance.handle(credentials);
        return response;
} catch(error){

        let message = commonCopy.apiErrors.networkError;

        if((error.code && error.code == 403 )|| (error.status && error.status == 403)){
                dispatch(setUserIsNotEnabled());
                return rejectWithValue(commonCopy.apiErrors.userNotEnabled);
        }

        if (error.response) {
                message = error.response.data.msg;

        } else {
                message = error.message;
        }

        return rejectWithValue(message);
}



},
{
    condition: (credentials, { getState, extra }) => {

            const state = getState() as RootState;

            if(state.apis.init.isLoading) return false;
    }
});

export const setUserIsNotEnabled = createAction('SET_USER_IS_NOT_ENABLED');