import React from 'react';
import KeyboardIcon from "../Presentational/Images/Icons/KeyboardIcon";
import StoreAMessageButton from "../Presentational/Buttons/SendAMessageButton";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "react-router";
import PenBlackIcon from "../Presentational/Images/Icons/PenBlackIcon";
import ModalConfirmActivity from "../Presentational/Modals/ModalConfirmActivity";
import {HOME_ROUTE} from "../../routes";
import ModalPrivacy from "../Presentational/Modals/ModalPrivacy";
import ModalMessageSubmitStatus from "../Presentational/Modals/ModalMessageSubmitStatus";
import BackHomeButton from "../Presentational/Buttons/BackHomeButton";
import ModalTypeTextSyncedField from "../Presentational/Modals/ModalTypeTextSyncedField";
import ModalWriteSign from "../Presentational/Modals/ModalWriteSign";
import ErrorsAlert from "../Presentational/Auth/ErrorsAlert";
import InputFormikField from '../Presentational/FormikFields/InputFormikField';
import messagesCopy from "../../lang/it/messages";
import {ICustom, IDeceased, IPingSuccessResponse, ISignSubmitData} from "../../typization/commonTypesInterfaces";
import StyledThanksgivingForm, {IThanksgivingFormValues} from "../Presentational/ThanksgivingForm";
import StyledModalThanksgiving from "../Presentational/Modals/ModalThanksgiving";

interface MessagesScenePropsInterface {
    deceased: IDeceased;
    performPing: any;
    updateShouldPing: (shouldPing : boolean) => void;
    history: any;
    location: any;
    match: any;
    customs: ICustom;
    pingApiError: string;
}

interface MessagesSceneStateInterface {
    isSubmitting: boolean;
    modalConfirmActivityOpen : boolean;
    modalPrivacyOpen : boolean;
    modalMessageSubmitStatusOpen : boolean;
    modalTypeMessageOpen : boolean;
    modalWriteSignOpen : boolean;
    modalSenderOpen: boolean;
    modalContactBackMailOpen: boolean;
    modalThanksgivingOpen: boolean;
}

interface FormValues {
    mittente: string;
    msg: string;
    base64_png: string;
    privacy: boolean;
    thanksgiving: {
        indirizzo: string;
        email: string;
        numero: string;
        canale : 'scegli' | 'whatsapp' | 'sms' | 'telegram';
    }
}

class MessagesScene extends React.Component<MessagesScenePropsInterface, MessagesSceneStateInterface> {

    private intervalBackToHome : any;
    state = {
        isSubmitting: false,
        modalConfirmActivityOpen: false,
        modalPrivacyOpen: false,
        modalMessageSubmitStatusOpen : false,
        modalTypeMessageOpen : false,
        modalWriteSignOpen : false,
        modalSenderOpen : false,
        modalContactBackMailOpen: false,
        modalThanksgivingOpen: false,
    };

    componentDidMount(): void {
        this.props.updateShouldPing(false);
        this.initBackToHomeTimer();
    }

    componentWillUnmount(): void {
        this.props.updateShouldPing(true);
        this.dismissBackToHomeTimer();
    }
    //****** Modal Sender Methods **** //

    openModalSender = () => {
        this.setState({modalSenderOpen:true})
    };

    closeModalSender = () => {
        this.setState({modalSenderOpen:false})
    };

    //****** Modal ContactBackMail Methods **** //

    openModalContactBackMail = () => {
        this.setState({modalContactBackMailOpen:true})
    };

    closeModalContactBackMail = () => {
        this.setState({modalContactBackMailOpen:false})
    };

    //****** Modal WriteSign Methods **** //

    openModalWriteSign = () => {
        this.setState({modalWriteSignOpen:true})
    };

    closeModalWriteSign = () => {
        this.setState({modalWriteSignOpen:false})
    };
    //****** Modal Privacy Methods **** //

    openModalPrivacy = () => {
        this.setState({modalPrivacyOpen:true})
    };

    closeModalPrivacy = () => {
        this.setState({modalPrivacyOpen:false})
    };
    //****** Modal TypeMessage Methods **** //

    openModalTypeMessage = () => {
        this.setState({modalTypeMessageOpen:true})
    };

    closeModalTypeMessage = () => {
        this.setState({modalTypeMessageOpen:false})
    };

    //****** Modal Thanksgiving Methods **** //

    openModalThanksgiving = () => {
        this.setState({modalThanksgivingOpen:true})
    };

    closeModalThanksgiving = () => {
        this.setState({modalThanksgivingOpen:false})
    };

    //****** Modal UserIsInteracting Methods **** //

    openModalUserIsInteracting = () => {
        if(this.intervalBackToHome){
            clearTimeout(this.intervalBackToHome);
        }
        this.setState({modalPrivacyOpen:false},() => {
           this.setState({modalConfirmActivityOpen: true})
       })
    };

    dismissModalUserIsInteracting = () => {
        this.setState({modalConfirmActivityOpen:false}, () => {
            this.initBackToHomeTimer();
        });
    };

    goBackHome = () => {
        this.props.updateShouldPing(true);
        this.props.performPing()
            .then((response: IPingSuccessResponse) => {
                this.props.history.push(HOME_ROUTE);
            })
            .catch(( _ : any) => {
                this.props.history.push(HOME_ROUTE);
            })
    };

    //**** BackToHomeTiming Methods ***** //

    dismissBackToHomeTimer = () => {
        if(this.intervalBackToHome){
            clearTimeout(this.intervalBackToHome);
        }
    };

    initBackToHomeTimer = () => {
        this.dismissBackToHomeTimer();
        this.intervalBackToHome = setInterval(this.openModalUserIsInteracting, 600000);//
    };

    //**** Form Methods ***** //

    onSubmitMessageClicked : (fields : FormValues) => void = (fields) => {

        if(this.state.isSubmitting) {
            return;
        }

        this.setState({isSubmitting: true}, () => {

            let canvasData = btoa(fields.base64_png);

            let submitSign : ISignSubmitData = {...fields, base64_png : canvasData, id_defunto: this.props.deceased.id};

            this.props.performPing({submitSign})
                .then((response : IPingSuccessResponse) => {

                        this.setState({modalMessageSubmitStatusOpen: true });
                    })
                .catch((error : any) => {

                    console.log('sign submit error', error);
                    //TODO:: ERROR HANDLING
                    this.setState({modalMessageSubmitStatusOpen: true});
                });
        });
    };

    getFormInitialValues =  () : FormValues  => {

        return {
            mittente: '',
            msg: '',
            base64_png: '',
            privacy: false,
            thanksgiving: {
                indirizzo: '',
                email: '',
                numero: '',
                canale : 'scegli' as 'scegli' | 'whatsapp' | 'sms' | 'telegram',
            }
        }

    };

    getFormValidationSchema = () => {
        return Yup.object().shape({
            mittente: Yup.string().required('Mittente Richiesto'),
            msg: Yup.string().nullable(true),
            privacy: Yup.bool().test(
                'privacy',
                'Devi aver letto l\'informativa sulla privacy per continuare',
                value => value === true
            )
                .required(
                    'Devi aver letto l\'informativa sulla privacy per continuare'
                ),
        })
    };

    render() {

        let errorsContent = this.props.pingApiError !== ''? <ErrorsAlert errors={[this.props.pingApiError]}/> : null;

        return (
            <div id="messages-scene" className="container-fluid container-scene pt-30 pb-15 pl-15 pr-15">
                <div className="row mb-25">
                    <div className="col-10 offset-sm-1">
                        <h1 className={'page-title text-center text-standard-violet pb-15'}>{messagesCopy.title}</h1>
                        <h4 className={'page-claim font-weight-normal text-center'}>
                            {messagesCopy.subtitle} <span className={'font-weight-bold '}>{this.props.deceased.baseInfos?.name || ''}</span>
                            <br/>
                            {messagesCopy.serviceBy} <span className={'font-weight-bold mt-15 d-inline-block'}>{this.props.customs?.r_sociale || ''}</span>
                        </h4>
                    </div>
                </div>
                <Formik
                    initialValues={this.getFormInitialValues()}
                    validationSchema={ this.getFormValidationSchema}
                    onSubmit={this.onSubmitMessageClicked}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {(formikProps) => {

                        const onThanksgivingSubmit = (values: IThanksgivingFormValues) => {
                            formikProps.setFieldValue('thanksgiving.indirizzo', values.address);
                            formikProps.setFieldValue('thanksgiving.email', values.email);
                            if(values.mobile != ''){
                                formikProps.setFieldValue('thanksgiving.numero', values.mobile);
                                formikProps.setFieldValue('thanksgiving.canale', values.channel);
                            }
                            this.closeModalThanksgiving()
                        };

                        return (

                                <React.Fragment>
                                    <Form>

                                        <div className="container-sign base-radius bg-standard-grey text-center pt-25">
                                            <p>{messagesCopy.form.title}</p>
                                            <div className="mr-15 ml-15 mt-15 pb-15 mb-15">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div
                                                            className="keyboard-text-container position-relative bg-white w-100 h-100 text-center p-3"
                                                            onClick={this.openModalTypeMessage}
                                                        >
                                                            <KeyboardIcon className={'mb-2'}/>
                                                            <h4 className={'text-uppercase'}>{formikProps.values.msg !== '' ? messagesCopy.form.message.edit : messagesCopy.form.message.write}</h4>
                                                            <div className="bordered-submitted-canvas overflow-y-auto p-3 text-left">
                                                                <p>{formikProps.values.msg}</p>
                                                                <Field type="hidden" name="msg" placeholder=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 handsign-area" onClick={this.openModalWriteSign}>
                                                        <div className="sign-container position-relative bg-white text-center p-3">
                                                            <PenBlackIcon className={'mb-2'}/>
                                                            <h4 className={'text-uppercase'}>{formikProps.values.msg !== '' ? messagesCopy.form.canvasSign.edit : messagesCopy.form.canvasSign.write}</h4>
                                                            <div className="bordered-submitted-canvas">
                                                                {formikProps.values.base64_png !== '' ? <img className={'mw-100'} src={formikProps.values.base64_png} alt={''} /> : null}
                                                                <Field type="hidden" name="base64_png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-5 offset-1">
                                                <div className={'form-group'} onClick={this.openModalSender}>
                                                    <div className={'form-control ' + (formikProps.errors['mittente'] && formikProps.touched['mittente'] ? ' is-invalid' : '')}>
                                                        {formikProps.values.mittente && formikProps.values.mittente.length > 0 ? formikProps.values.mittente : messagesCopy.form.sender}
                                                    </div>
                                                </div>
                                                <InputFormikField
                                                    type={'hidden'}
                                                    name={'mittente'}
                                                    {...formikProps}
                                                    withLabel={false}
                                                    placeholder={messagesCopy.form.sender}
                                                    withErrorMessage={false} withErrorTooltip={true}
                                                />
                                            </div>
                                            <div className="col-5">
                                                <div className={'form-group cursor-pointer'} >
                                                    <div className={'form-control bg-standard-violet base-radius text-white text-center w-100'} onClick={this.openModalThanksgiving}>
                                                        {messagesCopy.form.thanksgiving.triggerLabel}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-center'}>
                                            <div className={"form-group d-inline-block privacy"}>
                                                <div className={"form-check"}>
                                                    <InputFormikField
                                                        type={'checkbox'}
                                                        name={'privacy'}
                                                        {...formikProps}
                                                        withLabel={false}
                                                        withErrorMessage={false}
                                                        withErrorTooltip={true}
                                                        tooltipPlacement={"bottom"}
                                                    />
                                                    <p className={'label d-inline-block ' + (formikProps.errors.privacy && formikProps.touched.privacy ? ' is-invalid' : '')}>Ho letto l'<u
                                                        className={'cursor-pointer'} onClick={this.openModalPrivacy}>informativa
                                                        privacy</u> ed acconsento al trattamento dei miei dati <span>*</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-15">
                                            <div className="col-1">
                                                <BackHomeButton
                                                    onBtnPressed={this.goBackHome}
                                                    className={'bg-secondary  base-radius text-white text-center w-100'}
                                                />
                                            </div>
                                            <div className="col-6 offset-4  ">
                                                <StoreAMessageButton
                                                    className={'bg-standard-violet base-radius text-white text-center w-100'}
                                                />
                                            </div>

                                            {errorsContent}
                                        </div>


                                    </Form>

                                    <ModalConfirmActivity isOpen={this.state.modalConfirmActivityOpen} dismissModal={this.dismissModalUserIsInteracting} secondsToEnd={30} onTimeOver={this.goBackHome} ofLogoImg={this.props.customs.logo}/>

                                    <ModalMessageSubmitStatus isOpen={this.state.modalMessageSubmitStatusOpen} secondsToEnd={3} onTimeOver={this.goBackHome} submitSuccess={!this.props.pingApiError}/>

                                    <ModalPrivacy isOpen={this.state.modalPrivacyOpen} dismissModal={this.closeModalPrivacy}/>

                                    <ModalTypeTextSyncedField
                                        className={'message-modal'}
                                        fieldLabel={messagesCopy.form.modals.titles.message}
                                        syncFieldName={'msg'}
                                        fieldRows={5}
                                        isOpen={this.state.modalTypeMessageOpen}
                                        dismissModal={this.closeModalTypeMessage}
                                        onFieldSubmit={formikProps.setFieldValue}
                                        latestSubmittedValue={formikProps.values.msg}
                                    />

                                    <ModalWriteSign isOpen={this.state.modalWriteSignOpen} dismissModal={this.closeModalWriteSign} onFieldSubmit={formikProps.setFieldValue} />

                                    <ModalTypeTextSyncedField
                                        className={'sender-modal'}
                                        layoutDirection={"vertical"}
                                        fieldLabel={messagesCopy.form.modals.titles.sender}
                                        syncFieldName={'mittente'}
                                        fieldRows={1}
                                        isOpen={this.state.modalSenderOpen}
                                        dismissModal={this.closeModalSender}
                                        onFieldSubmit={formikProps.setFieldValue}
                                        latestSubmittedValue={formikProps.values.mittente}
                                    />

                                    <StyledModalThanksgiving
                                        isOpen={this.state.modalThanksgivingOpen}
                                        dismissModal={this.closeModalThanksgiving}
                                    >
                                        <StyledThanksgivingForm
                                            onSubmit={onThanksgivingSubmit}
                                        />
                                    </StyledModalThanksgiving>
                            </React.Fragment>
                        )
                    }}
                </Formik>

            </div>
        )
    }

}

export default withRouter(MessagesScene);