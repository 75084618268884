import React from 'react';
import Button from '../Base/Button';
import StoreYourMessageIcon from "../Images/Icons/StoreYourMessageIcon";
import messagesCopy from "../../../lang/it/messages";

interface StoreAMessagePropsInteface {
    className? : string;
}

const StoreAMessageButton : React.FC<StoreAMessagePropsInteface> = ({ className = ''}) => (
    <Button className={'leave-a-message ' + className} type={'submit'} label={messagesCopy.buttons.submitSign}>
        <StoreYourMessageIcon />
    </Button>
);

export default StoreAMessageButton;