
export default {
    exit : {
        regular: 'esci',
        uppercaseFirst : 'Esci',
        uppercase: 'ESCI'
    },
    save: {
        regular: 'salva',
        uppercaseFirst: 'Salva',
        uppercase: 'SALVA'
    },
    saveAndExit: {
        uppercaseFirst: 'Salva e esci'
    },
    apiErrors : {
        networkError: 'Errore di Rete, riprovare',
        userNotEnabled: 'Utente non abilitato',

    }
}