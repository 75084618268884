import React from 'react';

export interface IKeyboardIconProps {
    className? : string;
}

const KeyboardIcon : React.FC<IKeyboardIconProps> = ({className = ''}) => (
    <img className={'icon icon-keyboard ' + className} src={'/images/icons/keyboard.png'} alt={'keyboard'}/>
);

export default KeyboardIcon;