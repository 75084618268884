import React, { useRef} from 'react';
import Modal from 'react-bootstrap4-modal';
import {Field, FieldProps, Form, Formik} from "formik";
import * as Yup from "yup";
import KeyboardIcon from "../Images/Icons/KeyboardIcon";
import BackHomeButton from "../Buttons/BackHomeButton";
import Button from "../Base/Button";
import DeleteIcon from "../Images/Icons/DeleteIcon";
import messagesCopy from "../../../lang/it/messages";

interface IModalTypeMessageProps {
    className?: string;
    isOpen : boolean;
    dismissModal: VoidFunction;
    syncFieldName: string;
    initialValue? : string;
    fieldLabel: string;
    fieldRows?: number;
    onFieldSubmit : (field: string, value: any, shouldValidate?: boolean) => void;
    latestSubmittedValue: string; //stores latest,
    layoutDirection?: 'horizontal'| 'vertical';
}

const ModalTypeTextSyncedField : React.FC<IModalTypeMessageProps> = ({className = '', layoutDirection = 'horizontal', isOpen, fieldRows = 6, dismissModal, initialValue = '', onFieldSubmit, latestSubmittedValue, syncFieldName, fieldLabel }) => {

    let syncMessageField = useRef<{syncFieldValue : (field: string, value: any, shouldValidate?: boolean) => void }>({syncFieldValue: () => {}});

    const onSubmitClicked = (values : {message: string}) => {
        onFieldSubmit(syncFieldName,values.message );
        dismissModal();
    };

    let onModalDismiss = () => {
        dismissModal();
        if(syncMessageField.current?.syncFieldValue){
            syncMessageField.current?.syncFieldValue('message', latestSubmittedValue);
        }
    };

    return (
        <Modal visible={isOpen} className={className + ' modal-type-message'} dialogClassName={'modal-xl modal-dialog modal-dialog-top'} onClickBackdrop={onModalDismiss}>
            <div className="modal-body text-center p-5 position-relative">
                <Formik
                    initialValues={{message: initialValue}}
                    validationSchema={Yup.object().shape({message: Yup.string().nullable(true)})}
                    onSubmit={onSubmitClicked}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {({errors, touched, ...formikProps}) => {

                        syncMessageField.current = {syncFieldValue : formikProps.setFieldValue};

                        const onResetField = () => {
                            formikProps.setFieldValue('message', '');
                        };
                        if(layoutDirection === 'vertical'){
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="position-relative">
                                                <DeleteIcon color={'black'} onClicked={onResetField} className={'position-absolute top-right'}/>
                                                <KeyboardIcon className={'position-absolute top-left d-none'}/>
                                                <h1>{fieldLabel}</h1>
                                                <div className="form-group mt-30">
                                                    <Field name="message" >
                                                        {(fieldProps : FieldProps) => (
                                                                <textarea
                                                                    {...fieldProps.field}
                                                                    autoFocus
                                                                    className={'form-control ' + (errors.message && touched.message ? ' is-invalid' : '')}
                                                                    placeholder={initialValue}
                                                                    rows={fieldRows}
                                                                />
                                                            )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-30">
                                            <div className="row">
                                                <div className="col-6">
                                                    <Button
                                                        className={'leave-a-message bg-standard-violet base-radius text-white text-center w-100'}
                                                        type={'submit'}
                                                        label={messagesCopy.form.modals.buttons.save}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <BackHomeButton onBtnPressed={onModalDismiss} className={'bg-secondary  base-radius text-white text-center w-100'}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-15">
                                            <p className={'d-none'}>{errors.message}</p>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }

                        if(layoutDirection === 'horizontal'){
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="position-relative">
                                                <DeleteIcon color={'black'} onClicked={onResetField} className={'position-absolute top-right'}/>
                                                <KeyboardIcon className={'position-absolute top-left d-none'}/>
                                                <h1>{fieldLabel}</h1>
                                                <div className="form-group">
                                                    <Field name="message" >
                                                        {(fieldProps : FieldProps) => (
                                                                <textarea
                                                                    {...fieldProps.field}
                                                                    autoFocus
                                                                    className={'form-control ' + (errors.message && touched.message ? ' is-invalid' : '')}
                                                                    placeholder={initialValue}
                                                                    rows={fieldRows}
                                                                />
                                                            )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 ">
                                                <div className={'h-100 d-flex flex-column justify-content-center '}>
                                                    <div className={'h-75 d-flex flex-column justify-content-between'}>
                                                    <Button
                                                        className={'leave-a-message bg-standard-violet base-radius text-white text-center w-100'}
                                                        type={'submit'}
                                                        label={messagesCopy.form.modals.buttons.save}
                                                    />

                                                    <BackHomeButton onBtnPressed={onModalDismiss} className={'bg-secondary  base-radius text-white text-center w-100'}/>

                                                    <p className={'d-none'}>{errors.message}</p>

                                                    </div>

                                                </div>

                                        </div>
                                    </div>
                                </Form>
                            );
                        }
                    }}

                </Formik>
            </div>
        </Modal>
    )

};

export default ModalTypeTextSyncedField;