import React from 'react';

export interface IAnnouncementIconProps {
    className? : string;
    isActive?: boolean;
}

const AnnouncementIcon : React.FC<IAnnouncementIconProps> = ({className = '', isActive = false}) => (
    <img className={'icon icon-announcement ' + className} src={`/images/icons/announcement${isActive ? '-active' : ''}.png`} alt={"announcement"}/>
);

export default AnnouncementIcon;