import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";

export const shouldPingApiPerformCallsSelector = (state:RootState) => state.apis.ping.shouldPing;

export const pingApiIsLoadingSelector = (state :RootState) => state.apis.ping.isLoading;

export const pingApiTimingBetweenCallsSelector = (state:RootState) => state.apis.ping.settings.timing;

export const nextAppPathSelector = (state: RootState) => state.apis.ping.nextAppPath;

export const pingApiFirstCallSelector = (state : RootState) => state.apis.ping.firstCall;

export const pingApiErrorSelector = (state: RootState) => state.apis.ping.error;

export const getPingApiFirstCallPerformed = createSelector(
    pingApiFirstCallSelector,
    (firstCall) => Boolean(firstCall) === false
);

export const getNextAppPath = createSelector(
    nextAppPathSelector,
    (nextPath) => nextPath
);

export const getCanPingApiTimerTick = createSelector(
    shouldPingApiPerformCallsSelector,
    pingApiIsLoadingSelector,
    (shouldCall, isLoading) => shouldCall && !isLoading
);