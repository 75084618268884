import React, {  useEffect} from 'react';
import { Route, Switch, Redirect, useLocation, useHistory} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../sass/main.scss';
import HomeScene from "./Scenes/HomeScene";
import MessagesScene from "./Scenes/MessagesScene";
import PrivateRoute from "./Presentational/Auth/PrivateRoute";
import LoginScene from "./Scenes/LoginScene";
import {MESSAGES_ROUTE,HOME_ROUTE, LOGIN_ROUTE, SCREENSAVER_ROUTE} from "../routes";
import ScreenSaverScene from "./Scenes/ScreenSaverScene";
import {fetchPingApi, pingApiSetShouldPing, setPingApiTiming} from "../redux/actions/pingApiActions";
import {unwrapResult} from "@reduxjs/toolkit";
import CountDown from "./Presentational/Timing/CountDown";
import {useDispatch, useSelector} from "react-redux";
import {deviceIdSelector, getIsAuthenticated} from "../redux/selectors/userSelectors";
import {
    getCanPingApiTimerTick,
    getNextAppPath, pingApiErrorSelector,
    pingApiTimingBetweenCallsSelector,
} from "../redux/selectors/pingApiSelectors";
import {getIsAuthenticatedAndFirstPingApiCallWasPerformed} from "../redux/selectors/mixedSelectors";
import {customsSelector} from "../redux/selectors/customsSelectors";
import {availableDeceasedSelector} from "../redux/selectors/availableDeceasedSelectors";
import {selectedDeceasedSelector} from "../redux/selectors/selectedDeceasedSelector";
import useEventListener from "@use-it/event-listener";
import {setDeviceIsOffline, setDeviceIsOnline} from "../redux/actions/deviceStatusActions";
import {deviceIsOnlineSelector} from "../redux/selectors/deviceStatusSelectors";
import StyledBannerDeviceOffline from './Presentational/Banners/BannerDeviceOffline';

const App : React.FC<{}> = props => {

    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();
    const deviceId = useSelector(deviceIdSelector);
    const customs = useSelector(customsSelector);
    const pingApiTimingBetweenCalls = useSelector(pingApiTimingBetweenCallsSelector);
    const availableDeceased = useSelector(availableDeceasedSelector);
    const selectedDeceased = useSelector(selectedDeceasedSelector);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const nextAppDrivenPath = useSelector(getNextAppPath);
    const isAuthenticatedAndFirstPingApiCallWasPerformed = useSelector(getIsAuthenticatedAndFirstPingApiCallWasPerformed);
    const canPingApiTimerTick = useSelector(getCanPingApiTimerTick);
    const pingApiError = useSelector(pingApiErrorSelector);
    const deviceIsOnline = useSelector(deviceIsOnlineSelector);

    useEventListener('offline', () => {
        dispatch(setDeviceIsOffline());
    });

    useEventListener('online', () => {
        dispatch(setDeviceIsOnline());
    });

    const locationIsSameAsPath = (path : string) => {
        const {pathname} = location;
        return pathname === path;
    };

    const ping = async (pingApiRequest = {} ) => {

        try {
            const resultPingAction = await dispatch(fetchPingApi(pingApiRequest));
            //@ts-ignore
            return unwrapResult(resultPingAction);

        } catch(e){
            throw e;
        }


    };

    const updateShouldPing = (shouldPing: boolean) => {
        dispatch(pingApiSetShouldPing(shouldPing));
    };

    useEffect(() => {
        if(isAuthenticated) {
            updateShouldPing(true);
            setPingApiTiming(1);
            ping()
                .then(() => {
                    if(!locationIsSameAsPath(nextAppDrivenPath)){
                        //@ts-ignore
                        history.push(nextAppDrivenPath);
                    }
                })
                .catch(() => {

                });
        }

    },[]);

    useEffect(() => {
        if(isAuthenticatedAndFirstPingApiCallWasPerformed === true){
            if(!locationIsSameAsPath(nextAppDrivenPath)){
                //@ts-ignore
                history.push(nextAppDrivenPath);
            }
        }
    },[isAuthenticatedAndFirstPingApiCallWasPerformed, nextAppDrivenPath]);

    let scenesProps = {
        deviceId: deviceId,
        performPing: ping,
        customs: customs,
        showContent : isAuthenticatedAndFirstPingApiCallWasPerformed
    };

    return (
        <>
            <CountDown secondsToEnd={pingApiTimingBetweenCalls} isCounting={canPingApiTimerTick} onTimeOver={ping} />
            <main>
                <Switch>
                    <Route exact path={LOGIN_ROUTE}>
                        <LoginScene/>
                    </Route>

                    <PrivateRoute exact path={HOME_ROUTE}>
                        <HomeScene
                            {...scenesProps}
                        />
                    </PrivateRoute>

                    <PrivateRoute exact path={SCREENSAVER_ROUTE}>
                        <ScreenSaverScene
                            {...scenesProps}
                            persons={availableDeceased}
                        />
                    </PrivateRoute>

                    <PrivateRoute exact path={MESSAGES_ROUTE}>
                        <MessagesScene
                            {...scenesProps}
                            pingApiError={pingApiError}
                            deceased={selectedDeceased}
                            updateShouldPing={updateShouldPing}
                        />
                    </PrivateRoute>

                    <Route path={'*'}>
                        <Redirect to="/" />
                    </Route>

                </Switch>
                <StyledBannerDeviceOffline />
            </main>
        </>
    );

};

export default App;
