import React from 'react';

type IconColor = 'white'| 'black';

export interface ISendYourMessageIconProps {
    className? : string;
    color?: IconColor;
}

const iconNameByColor = {
    white: 'send-message-white',
    black: 'send-message',
} as const;

const SendYourMessageIcon : React.FC<ISendYourMessageIconProps> = ({className = '', color = 'white'}) => {

    return  <img className={'icon icon-send-message ' + className} src={'/images/icons/' + iconNameByColor[color] +'.png'} alt={'send-your-message'}/>

};

export default SendYourMessageIcon;