import React from 'react';

export interface PersonImagePropsInterface {
    className?  : string;
    src: string;
}

const PersonImage : React.FC<PersonImagePropsInterface> = ({className = '', src }) => (
    <img className={'person-image ' + className} src={src} alt={'person'}/>
);

export default PersonImage;