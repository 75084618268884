import * as React from 'react';
import CardNecrologioHome from "../Presentational/Cards/CardNecrologioHome/CardNecrologioHome";
import MessagesBoard from "../Presentational/Messages/MessagesBoard";
import FooterNecrologiHome from "../Presentational/Footer/FooterNecrologiHome";
import {useState} from "react";
import ModalFullHomeDetail from "../Presentational/Modals/ModalFullHomeDetail";
import LoadingCircles from "../Presentational/Images/Icons/LoadingCircles";
import CountDown from "../Presentational/Timing/CountDown";
import {useSelector} from "react-redux";
import {selectedDeceasedSelector} from "../../redux/selectors/selectedDeceasedSelector";
import {customsSelector} from "../../redux/selectors/customsSelectors";
import HomeDetailModalContent from "../Presentational/Modals/HomeDetailModalContent";
import { IDetailsRaw} from "../../typization/commonTypesInterfaces";

interface HomeScenePropsInterface {
    showContent: boolean;
}

const HomeScene: React.FC<HomeScenePropsInterface> = ({showContent}) => {

    const selectedDeceased = useSelector(selectedDeceasedSelector);
    const customs = useSelector(customsSelector);

    const [detailModalContentName, setDetailModalContentName] = useState<'' | keyof IDetailsRaw>('');
    const [detailModalOpen, setDetailModalOpen] = useState(false);

    const [isLayerInteractVisible, setIsLayerInteractVisible] = useState(false);
    const [isLayerInteractTimerCounting, setIsLayerInteractTimerCounting] = useState(true);

    const onLayerInteractTimerExpired = () => {
        setIsLayerInteractVisible(true);
        setIsLayerInteractTimerCounting(false);
    };

    const onLayerInteractClicked = () => {
        setIsLayerInteractVisible(false);
        setIsLayerInteractTimerCounting(true);
    };

    if (!showContent) return (
        <div className={'loader-scene-home'}>
            <LoadingCircles className={'home-scene'}/>
        </div>
    );

    return (
        <>
            <div className="container-fluid container-scene home-scene position-relative">
                <div className="row h-100">
                    <div className="col-6 pt-15 pb-15">
                        <CardNecrologioHome
                            className={'detail-modal-picker'}
                            onDetailClick={(name) => {
                                setDetailModalContentName(name);
                                setDetailModalOpen(true);
                            }}
                            baseInfos={selectedDeceased.baseInfos}
                            details={selectedDeceased.details}
                        />
                    </div>
                    <div
                        className=" messages-and-footer col-6 pt-15 pb-15 h-100 d-flex flex-column justify-content-between">
                        <MessagesBoard messages={selectedDeceased.messages}/>
                        <FooterNecrologiHome customs={customs}/>
                    </div>
                </div>

                <ModalFullHomeDetail
                    dismissModal={() => {
                        setDetailModalContentName('');
                        setDetailModalOpen(false);
                    }}
                    isOpen={showContent && detailModalOpen}
                >
                    <HomeDetailModalContent
                        contentName={detailModalContentName}
                    />
                </ModalFullHomeDetail>


            </div>
            <CountDown secondsToEnd={180} isCounting={isLayerInteractTimerCounting}
                       onTimeOver={onLayerInteractTimerExpired}>
                {(timeLeft: number) => {
                    return (
                        <>
                            <div
                                onClick={onLayerInteractClicked}
                                className={'layer-cover-home bg-standard-violet ' + (!isLayerInteractVisible && 'd-none')}
                            />
                            <div
                                className={'layer-content bg-white p-5 ' + (!isLayerInteractVisible && 'd-none')}
                                onClick={onLayerInteractClicked}
                            >
                                <img className={'display-block mb-80'} src={customs.logo} alt={''}
                                     style={{width: '250px', margin: '0 auto'}}/>
                                <h1 className={'start-claim text-standard-violet'}>Tocca lo schermo per iniziare</h1>
                            </div>
                        </>
                    )
                }}
            </CountDown>
        </>
    );

};

export default HomeScene;
