import React from 'react';

export interface ILoginMethodChoicheProps {
    activeLoginMethod : 'user-credentials' | 'deviceid' | '';
    onLoginMethodPicked: (method: 'user-credentials' | 'deviceid') => void
}

export const LoginMethodChoice : React.FC<ILoginMethodChoicheProps> = (props) => {

        return (
            <div className={`${props.activeLoginMethod !== '' && 'd-none'}`}>
                <h3 className={'mb-20 font-weight-bold text-center'}>Login di primo accesso</h3>

                <p className={'access-now mb-20'}>Inserisci username e password con cui accedi a Necrologi Italia</p>

                <div className=" text-center mt-35">
                    <button type='button' onClick={() => {props.onLoginMethodPicked('user-credentials')}} className={'button pt-5 pb-5 w-75 h-auto btn-login bg-standard-violet base-radius text-white text-uppercase font-weight-normal'}>ACCEDI</button>
                </div>

                <h5 className={'text-center font-weight-bold mt-35'}>Se possiedi già un device id:</h5>

                <p className={'instructions mt-15'}>
                    Questo codice è da inserire solamente nel caso in cui stai sostituendo un vecchio tablet
                    con questo nuovo dispositivo.
                    <br/>
                    Sul portale Necrologi Italia, nella sezione dedicata a Kiosk, troverai il vecchio DeviceID, da associare
                    a questo nuovo tablet
                </p>

                <div className="text-center mt-35">
                    <button type='button' onClick={() => {props.onLoginMethodPicked('deviceid')}} className={'button pt-5 pb-5 w-75 h-auto btn-login bg-standard-violet base-radius text-white text-uppercase font-weight-normal'}>ASSOCIA</button>
                </div>
            </div>
        )


};