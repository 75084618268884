import {createSlice} from "@reduxjs/toolkit";
import {authenticateUserByCredentials} from "../actions/userActions";
import {fetchPingApi} from "../actions/pingApiActions";
import {ICustom} from "../../typization/commonTypesInterfaces";

// **** SLICE STATE TYPE ******//

export type customsSliceState = ICustom;
/*
    r_sociale: string;
    logo: string;
    banner: string;
*/

const getInitialState = () :  customsSliceState => {

    //@ts-ignore
    if(window.initialCypressState){
        //@ts-ignore
        return {...window.initialCypressState.customs}
    }

    let storedCustoms : string | null = localStorage.getItem('customs');
    if (storedCustoms && storedCustoms !== "undefined"){
        let parsedCustoms : customsSliceState = JSON.parse(storedCustoms);
        if ('r_sociale' in parsedCustoms && 'logo' in parsedCustoms && 'banner' in parsedCustoms){
            return parsedCustoms;
        }
    }
    return {} as customsSliceState;
};

// **** SLICE ******//

export const customsSlice = createSlice({
    name: 'customs',
    initialState: getInitialState() as customsSliceState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authenticateUserByCredentials.fulfilled, (state, action) => {
                if(action.payload?.customs){
                    for (let key in action.payload.customs){
                        //@ts-ignore
                        state[key] = action.payload.customs[key];
                    }
                }
            })
            .addCase(fetchPingApi.fulfilled, (state, action) => {
                if(action.payload?.customs){
                    for (let key in action.payload.customs){
                        //@ts-ignore
                        state[key] = action.payload.customs[key];
                    }
                }
            })
            .addDefaultCase((state, action) => {
            })
    }
});
