import React from 'react';
import LeaveAMessageButton from "../Buttons/LeaveAMessageButton";
import FooterNecrologi from "./FooterNecrologi";
import {withRouter} from "react-router";
import {MESSAGES_ROUTE} from "../../../routes";
import {ICustom} from "../../../typization/commonTypesInterfaces";

const FooterNecrologiHome : React.FC<{history: any; match: any; location: any; customs: ICustom }>  = (props) => {

    const reachMessages = () => {
        props.history.push(MESSAGES_ROUTE);
    };

    return (
        <FooterNecrologi className={'footer-home text-center' }>
            <LeaveAMessageButton className={'bg-standard-violet base-radius text-white m-auto'} onBtnPressed={reachMessages} />
            <p className={'mt-5'}>Servizio offerto da {props.customs.r_sociale} in collaborazione con Necrologi Italia </p>
        </FooterNecrologi>
    )
};

export default withRouter(FooterNecrologiHome);