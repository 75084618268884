import React from 'react';

export interface INecrologiItaliaLogoProps {
    className?: string;
}

const NecrologiItaliaLogo : React.FC<INecrologiItaliaLogoProps> = ({className = ''}) => (
    <img className={'logo ni-italia-logo ' + className} src={'/images/logos/ni-logo.png'} alt={"necrologi-italia"}/>
);

export default NecrologiItaliaLogo;