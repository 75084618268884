import React from 'react';
import Message from "./Message";
import {ISign} from "../../../typization/commonTypesInterfaces";

interface MessagesBoardPropsInterface {
    messages: ISign[];
}

const MessagesBoard : React.FC<MessagesBoardPropsInterface> = ({messages = []}) => {
    return (
        <div className="messages-board">
            <div className="board-head bg-darker-grey text-center pt-10 pb-10">
                <p className={'mb-0'}>Hanno già manifestato la loro vicinanza:</p>
            </div>
            <div className="board-body bg-standard-grey container">
                {messages.map((message, index ) => <Message className={'pt-15 pb-15'} key={index} message={message} />)}
            </div>
        </div>
    )
};

export default MessagesBoard;