import commonCopy from "./common";

export default {
    title: 'ESPRIMI IL TUO CORDOGLIO',
    subtitle: 'Questo tuo pensiero verrà stampato e consegnato a mano alla famiglia di',
    serviceBy: 'Servizio offerto da',
    form: {
        title: 'Puoi digitare il tuo messaggio con la tastiera e firmare a mano libera',
        message: {
            write: 'Tocca per scrivere il tuo messaggio',
            edit: 'Tocca per correggere il tuo messaggio'
        },
        canvasSign: {
            write: 'Tocca per lasciare la tua firma',
            edit: 'Tocca per correggere la tua firma',
        },
        sender: 'Mittente o famiglia che esprime vicinanza',
        email: 'Email per ricezione di un eventuale ringraziamento',
        thanksgiving: {
            triggerLabel : 'Desideri ricevere un eventuale ringraziamento della famiglia?',
            modal: {
              howToReceive: 'Per ricevere il ringraziamento',
              chooseMode: 'Scegli tra posta tradizionale, email o cellulare.',
              fillAtLeastAField: '(Compila almeno uno dei campi)'
            },
            placeholders: {
                address: 'Indirizzo abitazione. Es: Via Verdi 1, Roma',
                email: 'Email',
                mobile: 'Numero di cellulare',
            },
            select: {
                channels : {
                    choose: {
                        label: 'Scegli modalità',
                        value: 'scegli',
                    },
                    whatsapp: {
                        label: 'Whatsapp',
                        value: 'whatsapp'
                    },
                    telegram: {
                        label: 'Telegram',
                        value: 'telegram'
                    },
                    sms: {
                        label: 'SMS',
                        value: 'sms'
                    },
                }
            },
            validation: {
                address: {
                    required: 'Indirizzo Richiesto',
                },
                email: {
                    required: 'Email richiesta',
                    invalid: 'Email non valida'
                },
                mobile: {
                    required: 'Numero di telefono richiesto',
                    length: 'Numero di telefono deve essere composto da 10 numeri'
                },
                channel : {
                    invalid: 'Selezione non valida'
                }

            },
            submit: 'OK'
        },
        privacy: "Ho letto l'informativa privacy ed acconsento al trattamento dei miei dati",
        modals: {
            titles: {
                message: 'Scrivi il messaggio',
                canvasSign: 'Firma il messaggio',
                sender: 'Mittente',
                email: 'Tua mail',
            },
            buttons: {
                save: commonCopy.save.uppercaseFirst,
                exit: commonCopy.exit.uppercase,
                saveAndExit: commonCopy.saveAndExit.uppercaseFirst
            }
        }
    },
    buttons: {
        backHome: commonCopy.exit.uppercase,
        submitSign: 'SALVA IL TUO MESSAGGIO'
    },
    submit : {
        success: 'il tuo messaggio è stato registrato con successo',
        failure: "E' stato impossibile registrare il tuo messaggio",
    }

};