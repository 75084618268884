import React from 'react';

export interface IPenBlackIconProps {
    className? : string;
}

const PenBlackIcon : React.FC<IPenBlackIconProps> = ({className = ''}) => (
    <img className={'icon icon-pen-black ' + className} src={'/images/icons/pen-black.png'} alt={'pen-black-icon'}/>
);

export default PenBlackIcon;