import React from 'react';

type IconColor = 'white'| 'black';

export interface IStoreYourMessageIconProps {
    className? : string;
    color?: IconColor;
}

const iconNameByColor = {
    white: 'store-message-white',
    black: 'store-message',
} as const;

const StoreYourMessageIcon : React.FC<IStoreYourMessageIconProps> = ({className = '', color = 'white'}) => {

    return <img className={'icon icon-store-message ' + className} src={'/images/icons/'+ iconNameByColor[color] + '.png'} alt={"store-your-message"}/>
};


export default StoreYourMessageIcon;