import React from 'react';
import Modal from 'react-bootstrap4-modal';
import CloseModalIcon from "./CloseModalIcon";

interface IModalFullHomeDetailProps {
    isOpen: boolean;
    dismissModal: VoidFunction;
}

const ModalFullHomeDetail: React.FC<IModalFullHomeDetailProps> = ({isOpen, dismissModal, children}) => {

    return (
        <Modal visible={isOpen} className={'modal-full-home-detail'}
               dialogClassName={'modal-xl modal-dialog modal-dialog-centered p-4'} onClickBackdrop={dismissModal}>
            <div className="modal-body text-center p-5 position-relative">
                <CloseModalIcon onClicked={dismissModal} className={'position-absolute top-right'}/>
                {children}
            </div>
        </Modal>
    )

};

export default ModalFullHomeDetail;