import React from 'react';
import {Form, Formik} from "formik";
import InputFormikField from "../FormikFields/InputFormikField";
import PasswordInputFormikField from "../FormikFields/PasswordInputFormikField";
import * as Yup from "yup";
import {clientSideValidationErrors} from "../../Scenes/LoginScene";

const LoginFormUserCredentials =  ({ getFormInitialValues, onLoginClicked, isAttemptingLogin, className = '' }) => (

    <div className={'user-credentials ' + className} >

            <h3 className={'mb-20 font-weight-bold text-center'}>Login di primo accesso</h3>

            <p className={'access-now mb-20'}>Inserisci username e password con cui accedi a Necrologi Italia</p>

            <Formik
                initialValues={getFormInitialValues('user-credentials')}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={ Yup.object().shape({
                    username: Yup.string()
                        .email(clientSideValidationErrors.username.notValidEmail)
                        .required(clientSideValidationErrors.username.required),
                    password: Yup.string()
                        .min(6, clientSideValidationErrors.password.length.min)
                        .required(clientSideValidationErrors.password.required),
                })}
                onSubmit={(values, formikHelpers) => {
                    let normalizedValues = {
                        username: values.username.toLowerCase(),
                        password: values.password,
                        deviceId: values.deviceId.toLowerCase(),
                    };


                    onLoginClicked(normalizedValues)
                }}
            >
                {(formikProps) => (
                    <Form className={"login-form " + (isAttemptingLogin ? 'd-none' : '')} >

                        <InputFormikField name={'username'} values={formikProps.values} errors={formikProps.errors} touched={formikProps.touched} withLabel={false} placeholder={'Email'}/>

                        <PasswordInputFormikField name={'password'} values={formikProps.values} errors={formikProps.errors} touched={formikProps.touched} withLabel={false} placeholder={'Password'}/>


                        <InputFormikField type={'hidden'} name={'deviceId'} values={formikProps.values} errors={formikProps.errors} touched={formikProps.touched} withLabel={false} placeholder={'Device ID'}/>

                        <div className="form-group text-center mt-35">
                            <button type='submit' className={'button pt-5 pb-5 w-75 h-auto btn-login bg-standard-violet base-radius text-white text-uppercase font-weight-normal'}>ACCEDI</button>
                        </div>

                    </Form>
                )}
            </Formik>
        </div>

);

export default LoginFormUserCredentials;